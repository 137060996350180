/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import { Grid, Slider, Typography, Hidden, TextField } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import useStyles from './style';
import { dataPreference } from '@orientaction/utils';

export default function SliderScore(props: any) {
  const { pref, isEdit, isCanceled, restoreCanceled, handleNewData } = props;
  const classes = useStyles({ color: dataPreference[pref.category].color });
  const [valueLeft, setValueLeft] = useState(pref.score[0].pourcentage);
  const [valueRight, setValueRight] = useState(pref.score[1].pourcentage);

  const isMaxLeft = useMemo(() => {
    return valueLeft > valueRight;
  }, [valueLeft, valueRight]);

  const handleValueChangeRight = (e: any) => {
    if (
      (parseFloat(e.target.value) >= 0 && parseFloat(e.target.value) <= 100) ||
      e.target.value === ''
    ) {
      setValueRight(e.target.value);
      setValueLeft(calculPercentage(parseFloat(e.target.value)));
      handleNewData(pref.score[0].key, calculPercentage(parseFloat(e.target.value)));
      handleNewData(pref.score[1].key, parseFloat(e.target.value));
    }
  };
  const handleValueChangeLeft = (e: any) => {
    if (
      (parseFloat(e.target.value) >= 0 && parseFloat(e.target.value) <= 100) ||
      e.target.value === ''
    ) {
      setValueLeft(e.target.value);
      setValueRight(calculPercentage(parseFloat(e.target.value)));
      handleNewData(pref.score[0].key, parseFloat(e.target.value));
      handleNewData(pref.score[1].key, calculPercentage(parseFloat(e.target.value)));
    }
  };

  const calculPercentage = (valueToCalculate: number) => {
    return 100 - valueToCalculate;
  };

  useEffect(() => {
    if (isCanceled) {
      setValueLeft(pref.score[0].pourcentage);
      setValueRight(pref.score[1].pourcentage);
      restoreCanceled(false);
    }
  }, [isCanceled]);

  const formatNumber = (num: number) => {
    const parseNumber = Number(num);
    return parseFloat(parseNumber.toFixed(2));
  };

  return (
    <div className={classes.prefCard}>
      <Typography variant="body2" color="primary" className={classes.textHeader}>
        Vous êtes <b>{pref.youAre}</b>
      </Typography>
      <Grid container={true}>
        <Hidden only={['xs', 'sm']}>
          <Grid item={true} xs={1} sm={1} md={1} className={classes.flexCenter}>
            {!isEdit ? (
              <Typography
                className={classes.boldMedium}
                variant="body2"
                style={{ color: '#242424' }}>
                <b>{formatNumber(valueLeft)}%</b>
              </Typography>
            ) : (
              <div className={classes.containerField}>
                <TextField
                  id="response-value"
                  variant="outlined"
                  className={classes.textField}
                  name="responseValue"
                  onChange={handleValueChangeLeft}
                  value={valueLeft}
                  inputProps={{
                    type: 'number',
                    min: 0,
                    step: 1,
                  }}
                />
                <span>%</span>
              </div>
            )}
          </Grid>
        </Hidden>
        <Grid item={true} xs={12} sm={12} md={10} style={{ position: 'relative' }}>
          <div className={classes.hiddeDiv} />
          <div className={classes.widthSlider}>
            <Slider
              valueLabelDisplay="auto"
              aria-label="pretto slider"
              defaultValue={!isMaxLeft ? valueRight : valueLeft}
              classes={{
                root: classes.rootSlider,
                thumb: classes.thumb,
                track: classes.track,
                rail: classes.rail,
              }}
              value={!isMaxLeft ? valueRight : valueLeft}
              style={{ transform: !isMaxLeft ? 'rotateY(-190deg)' : 'rotateY(0deg)' }}
            />
          </div>
        </Grid>
        <Hidden only={['xs', 'sm']}>
          <Grid
            item={true}
            md={1}
            xs={1}
            sm={1}
            className={classes.flexCenter}
            style={{ justifyContent: 'flex-end' }}>
            {!isEdit ? (
              <Typography
                className={classes.boldMedium}
                variant="body2"
                style={{ color: '#242424' }}>
                <b>{formatNumber(valueRight)}%</b>
              </Typography>
            ) : (
              <div className={classes.containerField}>
                <TextField
                  id="response-value"
                  variant="outlined"
                  className={classes.textField}
                  name="responseValue"
                  onChange={handleValueChangeRight}
                  value={valueRight}
                  inputProps={{
                    type: 'number',
                    min: 0,
                    step: 1,
                  }}
                />
                <span>%</span>
              </div>
            )}
          </Grid>
        </Hidden>
      </Grid>
      <Grid container={true} justifyContent="space-between">
        <Grid item={true} md="auto" className={classes.flexCenter}>
          <Typography
            className={classes.boldMedium}
            variant="body2"
            style={{ color: '#242424', textTransform: 'uppercase' }}>
            <Hidden only={['md', 'lg', 'xl']}>
              <b>{pref.score[0].pourcentage}%</b> <br />
            </Hidden>
            <b>{pref.score[0].label}</b>
          </Typography>
        </Grid>
        <Grid item={true} md="auto" className={classes.flexCenter}>
          <Typography
            className={classes.boldMedium}
            variant="body2"
            style={{ color: '#242424', textTransform: 'uppercase', textAlign: 'right' }}>
            <Hidden only={['md', 'lg', 'xl']}>
              <b>{pref.score[1].pourcentage}%</b> <br />
            </Hidden>
            <b>{pref.score[1].label}</b>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
