/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ContentCenter, MarginContainer } from '@orientaction/commons';
import FirstEtage from '../ViewTest/FirstEtage';
import SecondEtage from '../ViewTest/SecondEtage';
import { useStyles } from './style';
import { useMe } from '@orientaction/hooks';

const TestMineur = ({ loading, minorTests, freeTests }: any) => {
  const classes = useStyles();
  const { loading: loadingMe, me } = useMe();

  return (
    <>
      <MarginContainer big={true}>
        <Grid container={true} direction="row" justifyContent="space-between" alignItems="center">
          <Grid item={true}>
            <Typography variant="h2" style={{ marginBottom: '20px', color: 'white' }}>
              TESTS MINEURS
            </Typography>
          </Grid>
        </Grid>

        <MarginContainer>
          {loading ? (
            <ContentCenter>
              <CircularProgress color="inherit" className={classes.colorWhite} />
            </ContentCenter>
          ) : (
            <>
              {minorTests.map((category: any) => {
                if (!category?.contentList?.length) {
                  return;
                }

                return (
                  <div key={category.id}>
                    <MarginContainer>
                      <Typography
                        variant="h4"
                        className={classes.colorWhite}
                        data-cy="category-name-mineur">
                        {' '}
                        {category?.name}
                      </Typography>
                    </MarginContainer>

                    <MarginContainer>
                      <FirstEtage
                        listFirstEtape={category.contentList}
                        me={me}
                        loading={loadingMe}
                        freeTests={freeTests}
                      />
                      <SecondEtage
                        listSecondEtape={category.contentList}
                        me={me}
                        loading={loadingMe}
                        freeTests={freeTests}
                      />

                      <br className={classes.both} />
                    </MarginContainer>
                  </div>
                );
              })}
            </>
          )}
        </MarginContainer>
      </MarginContainer>
    </>
  );
};

export default TestMineur;
