/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { userService } from '@orientaction/services';
import { emailMatch, publicSvgPath } from '@orientaction/utils';
import clsx from 'clsx';
import { FC, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import style from './style';

interface IProps {
  nextStep: (data: any) => void;
  setData: (data: any) => void;
  showAccountConfirm: boolean;
  handleAccountConfirm: (data: any) => void;
  dataStep1: any;
}

const FirstStep: FC<IProps> = ({
  nextStep,
  setData,
  showAccountConfirm,
  handleAccountConfirm,
  dataStep1,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [eyeOn, setEyeOn] = useState<boolean>(false);
  const [registerError, setRegisterError] = useState<string>('');

  const { pathname } = useLocation();

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<any>({
    mode: 'onChange',
  });

  const newPasswordRef = useRef({});

  newPasswordRef.current = watch('password', '');

  const confirmPasswordRef = useRef({});

  confirmPasswordRef.current = watch('confirmPassword', '');

  const classes = style();

  const onSubmitHandler = async (data: any) => {
    setIsLoading(true);

    try {
      await userService.verifyIfMailExist(data.email);
      setData(data);
      nextStep(1);
    } catch (error) {
      setRegisterError(
        "Cette adresse e-mail est déjà utilisée. Veuillez vous connecter ou changer d'adresse pour créer votre compte"
      );
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} className={classes.form}>
      <div className={classes.fieldWithLabel}>
        <Controller
          name="lastname"
          control={control}
          defaultValue={dataStep1.lastname}
          rules={{
            required: 'Champ obligatoire',
          }}
          render={({ field }) => (
            <>
              <label htmlFor="lastname">
                <h5 className={classes.labelText}>Nom</h5>
              </label>
              <Input
                placeholder="Tapez votre nom"
                {...field}
                type="text"
                className={classes.input}
                name="lastname"
              />

              {errors?.lastname?.message && (
                <span className={classes.errorField}>{(errors as any).lastname.message}</span>
              )}
            </>
          )}
        />
      </div>
      <div className={classes.fieldWithLabel}>
        <Controller
          name="firstname"
          control={control}
          defaultValue={dataStep1.firstname}
          rules={{
            required: 'Champ obligatoire',
          }}
          render={({ field }) => (
            <>
              <label htmlFor="firstname">
                <h5 className={classes.labelText}>Prénom</h5>
              </label>
              <Input
                placeholder="Tapez votre prénom"
                {...field}
                type="text"
                className={classes.input}
                name="firstname"
              />
              {errors?.firstname?.message && (
                <span className={classes.errorField}>{(errors as any).firstname.message}</span>
              )}
            </>
          )}
        />
      </div>
      <div className={classes.fieldWithLabel}>
        <Controller
          name="email"
          control={control}
          defaultValue={dataStep1.email}
          rules={{
            required: 'Champ obligatoire',
            pattern: {
              value: emailMatch,
              message: 'Veuillez saisir une adresse e-mail valide',
            },
          }}
          render={({ field }) => (
            <>
              <label htmlFor="email">
                <h5 className={classes.labelText}>Email</h5>
              </label>
              <Input
                placeholder="Tapez votre email"
                {...field}
                type="text"
                name="email"
                className={classes.input}
              />
              {errors?.email?.message && (
                <span className={classes.errorField}>{(errors as any).email.message}</span>
              )}
            </>
          )}
        />
      </div>
      <div className={classes.fieldWithLabel}>
        <Controller
          name="password"
          control={control}
          defaultValue={dataStep1.password}
          rules={{
            required: 'Champ obligatoire',
            pattern: {
              value:
                /^(((?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z]))|((?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]))).{8,}$/,
              message:
                'Votre mot de passe doit contenir au moins 8 caractères et au moins 3 des éléments suivants : Lettres minuscules (a-z) | Lettres majuscules (A-Z) | Chiffres (0-9) |Caractères spéciaux (ex. !@#)}',
            },
            validate: {
              value: value => {
                if (
                  confirmPasswordRef &&
                  confirmPasswordRef.current &&
                  confirmPasswordRef.current !== ''
                ) {
                  if (confirmPasswordRef.current === value) {
                    if (errors?.confirmPassword) {
                      (errors as any).confirmPassword.message = '';
                    }
                  } else if (errors?.confirmPassword) {
                    (errors as any).confirmPassword.message = "Le mot de passe n'est pas identique";
                  } else {
                    (errors as any).confirmPassword = {};
                    (errors as any).confirmPassword.message = "Le mot de passe n'est pas identique";
                  }
                }
                return newPasswordRef.current === value;
              },
            },
          }}
          render={({ field }) => (
            <>
              <label htmlFor="newPassword">
                <h5 className={classes.labelText}>Mot de passe</h5>
              </label>
              <Input
                placeholder="Tapez votre mot de passe"
                {...field}
                type={eyeOn ? 'text' : 'password'}
                className={clsx(classes.input, classes.inputPassword)}
                name="password"
                endAdornment={
                  <InputAdornment position="end">
                    {eyeOn ? (
                      <img
                        alt="eye-switch-on"
                        src={`${publicSvgPath}/eyeOn.svg`}
                        className={classes.eyeImg}
                        onClick={() => setEyeOn(false)}
                        onKeyDown={() => setEyeOn(false)}
                      />
                    ) : (
                      <img
                        alt="eye-switch-off"
                        src={`${publicSvgPath}/eyeOff.svg`}
                        className={classes.eyeImg}
                        onClick={() => setEyeOn(true)}
                        onKeyDown={() => setEyeOn(true)}
                      />
                    )}
                  </InputAdornment>
                }
              />
              {errors?.password?.message && (
                <span className={classes.passwordErrorField}>
                  {(errors as any).password.message}
                </span>
              )}
            </>
          )}
        />
      </div>
      <div className={classes.fieldWithLabel}>
        <Controller
          name="confirmPassword"
          control={control}
          defaultValue={dataStep1.confirmPassword}
          rules={{
            required: 'Champ obligatoire',
            validate: {
              value: value =>
                value === newPasswordRef.current || "Le mot de passe n'est pas identique",
            },
          }}
          render={({ field }) => (
            <>
              <label htmlFor="confirmPassword">
                <h5 className={classes.labelText}>Confirmation du mot de passe</h5>
              </label>
              <Input
                placeholder="Tapez votre mot de passe"
                {...field}
                type={eyeOn ? 'text' : 'password'}
                className={clsx(classes.input, classes.inputPassword)}
                name="confirmPassword"
                endAdornment={
                  <InputAdornment position="end">
                    {eyeOn ? (
                      <img
                        alt="eye-switch-on"
                        src={`${publicSvgPath}/eyeOn.svg`}
                        className={classes.eyeImg}
                        onClick={() => setEyeOn(false)}
                        onKeyDown={() => setEyeOn(false)}
                      />
                    ) : (
                      <img
                        alt="eye-switch-off"
                        src={`${publicSvgPath}/eyeOff.svg`}
                        className={classes.eyeImg}
                        onClick={() => setEyeOn(true)}
                        onKeyDown={() => setEyeOn(true)}
                      />
                    )}
                  </InputAdornment>
                }
              />
              {errors?.confirmPassword?.message && (
                <span className={classes.passwordErrorField}>
                  {(errors as any).confirmPassword.message}
                </span>
              )}
            </>
          )}
        />
      </div>
      <div className={classes.checkBoxCguContainer}>
        <Controller
          name="cgu"
          control={control}
          defaultValue={dataStep1.cgu}
          rules={{
            required: "Veuillez accepter les conditions générales d'utilisation",
          }}
          render={({ field }) => (
            <Checkbox
              {...field}
              checked={dataStep1.cgu}
              className={classes.checkBoxCgu}
              disableFocusRipple={true}
              disableRipple={true}
              disableTouchRipple={true}
              id="cgu"
              color="primary"
              data-cy="cgu-cy"
            />
          )}
        />
        <label htmlFor="cgu">
          <h5 className={classes.cguAcceptText}>
            J&#39;ai lu et j&#39;accepte les{' '}
            <Link
              className={classes.cguLinkText}
              to="../../../../../orientaction-cgu.pdf"
              target="_blank">
              conditions générales d&#39;utilisation
            </Link>
            <span> et la </span>
            <Link
              className={classes.cguLinkText}
              to="../../../../../orientaction-pdc.pdf"
              target="_blank"
            >
              Politique de confidentialité
            </Link>
          </h5>
        </label>
      </div>

      {errors && errors.cgu && errors.cgu.message && (
        <h5 className={classes.errorField}>{errors.cgu.message}</h5>
      )}

      {registerError?.trim()?.length > 0 && <h5 className={classes.errorField}>{registerError}</h5>}

      <Button
        type="submit"
        className={classes.btnConnect}
        disabled={isLoading}
        data-cy="step-one-register">
        {isLoading ? `Suivant...` : `Suivant`}
      </Button>
      <Box className={classes.loginLink}>
        <Link className={classes.loginLinkText} to={pathname}>
          Déjà inscrit·e ? Accédez à votre compte
        </Link>
      </Box>
    </form>
  );
};

export default FirstStep;
