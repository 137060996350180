/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import { ChangeEvent, FC, useRef, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import useStyles from './style';
import { InputSearch } from '@orientaction/commons';

interface ISearch {
  addNew: () => void;
  isResult?: boolean;
  wordFilter: string;
  handleFilterChange: (keyWord: string) => void;
  isUserConsultant?: boolean;
}

const Search: FC<ISearch> = ({
  addNew,
  isResult,
  wordFilter,
  handleFilterChange,
  isUserConsultant = false,
}) => {
  const classes = useStyles();

  const divRef = useRef<any>(null);

  const [showAdd, setShowAdd] = useState(true);

  const search = () => {
    // to be defined what to do here
    handleFilterChange(wordFilter);
  };

  useEffect(() => {
    setShowAdd(divRef?.current?.clientWidth > 95);
  }, []);

  window.addEventListener('resize', event => {
    setShowAdd(divRef?.current?.clientWidth > 95);
  });

  const handleChange = (event: any) => {
    const delayDebounceFn = setTimeout(() => {
      handleFilterChange(event.target.value);
    }, 3000);
    return () => clearTimeout(delayDebounceFn);
  };

  return (
    <>
      {!isResult && (
        <Grid
          item={true}
          container={true}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}>
          <Grid item={true} md={isUserConsultant ? 6 : 4}>
            <Button
              ref={divRef}
              className={classes.btnPrimary}
              style={{ textTransform: 'none' }}
              onClick={addNew}
              data-cy="btn-add-admin-benef">
              {isUserConsultant ? (
                'Ajouter à une prestation'
              ) : (
                <>
                  <Add />
                  {showAdd && (
                    <>
                      <span>Ajouter</span>
                    </>
                  )}
                </>
              )}
            </Button>
          </Grid>

          <Grid item={true} md={isUserConsultant ? 6 : 7}>
            <InputSearch
              placeholder="Chercher"
              onChange={handleChange}
              search={search}
              value={wordFilter}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Search;
