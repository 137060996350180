/* eslint-disable react-hooks/rules-of-hooks */
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { MarginContainer, OaPopin, SelectHieachy } from '@orientaction/commons';
import { useCompany } from '@orientaction/hooks';
import { getFromLS, valueAliasRole } from '@orientaction/utils';
import { FC, useContext, useEffect, useState } from 'react';
import { FORM_MODE, generatePasswordOptionalText } from '../constant';
import RandomPasswordSection from '../RandomPasswordSection';
import ServicesSection from './ServicesSection';
import style from './style';
import useManagerForm from './useManagerForm';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { apiServiceNative } from '../../../../utils/fetchNative';
import { UserFormContext } from '../Context/UserFormContext';
import { cloneDeep, filter, find, sortBy } from 'lodash';

interface IProps {
  mode: string;
  alias: any;
  companyUserRoleId: number;
}

const ManagerForm: FC<IProps> = ({ mode, alias, companyUserRoleId }) => {
  const { companyUserRole } = useCompany();
  const { updateFormUser } = useContext<any>(UserFormContext);
  const {
    loading,
    errors,
    user,
    handleSubmit,
    handleChange,
    handleRoleChange,
    closeDrawer,
    canUpdateHierarchy,
    servicesLoading,
    popin,
    handlePopinConfirm,
    handlePopinClose,
    tabs,
    isRandomPasswordCopied,
    generateRandomPassword,
    copyRandomPassword,
  } = useManagerForm(mode, companyUserRoleId);

  const [categorieTests, setCategorieTests] = useState<any>([]);

  if (!user) {
    return null;
  }

  const classes = style();

  const userConnected = JSON.parse(getFromLS('userInfos') || '{}');

  const btn = () => {
    if (loading) {
      return 'Chargement ... ';
    }

    if (mode === FORM_MODE.update) {
      return 'Enregistrer';
    }

    return 'Créer le compte';
  };
  const isConsultant = companyUserRoleId === 4;

  useEffect(() => {
    const fetchGameDetails = async () => {
      try {
        const response = await apiServiceNative.get(`/api/category-tests`);
        if (response) {
          console.log({ response });
          setCategorieTests(response.data);
        } else {
          console.error('Invalid response structure:', response);
        }
      } catch (error) {
        console.error('Error fetching game details:', error);
      }
    };

    fetchGameDetails();
  }, []);

  const handleChangeCategory = (e: any) => {
    const { name, value, checked } = e.target;
    const categorieToFind = find(categorieTests, (cat: any) => cat.id === parseInt(value, 10));
    const categorieTestsWithoutAttributes = {
      id: categorieToFind.id,
      ...categorieToFind.attributes,
    };

    const userCategories = cloneDeep(user?.category_tests) || [];
    console.log({ userCategories, categorieToFind });
    const isCategorieExistIntoUser = userCategories?.some(
      (elem: any) => elem?.id === parseInt(value, 10)
    );

    if (!isCategorieExistIntoUser && checked) {
      updateFormUser({
        ...user,
        category_tests: [...userCategories, categorieTestsWithoutAttributes],
      });
    } else {
      updateFormUser({
        ...user,
        category_tests: filter(
          userCategories,
          (elem: any) => elem.id !== categorieTestsWithoutAttributes?.id
        ),
      });
    }
  };

  useEffect(() => {
    console.log({ user });
  }, [user]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={classes.marginTop}>
          <Typography className={classes.label}>Rôle</Typography>
          <FormControl
            variant="outlined"
            style={{ width: '100%' }}
            error={!!errors.company_user_role}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              name="company_user_role"
              value={user.company_user_role || ''}
              onChange={handleRoleChange}
              placeholder="Entreprise"
              readOnly={!!(mode === 'View')}
              disabled={!!user?.hasMultipleRole}
              className={classes.formControlSelect}>
              {companyUserRole
                .map((item: any) => {
                  return {
                    ...item.attributes,
                    id: item.id,
                  };
                })
                .slice(userConnected?.company_user_role?.id || 0)
                .map((item: any) => (
                  <MenuItem value={item.id}>
                    {valueAliasRole(item.name, alias?.attributes?.companyUserRoleAlias)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        {tabs !== 0 && user.company_user_role !== 1 && (
          <div className={classes.marginTop}>
            <SelectHieachy
              value={user.upper_hierarchy}
              alias={alias}
              errorForm={errors}
              readOnly={mode === FORM_MODE.readonly || canUpdateHierarchy}
              title={`Administrateur de rattachement ${
                userConnected.role.type === 'admin' ? '' : '(facultatif)'
              }`}
              name="upper_hierarchy"
              handleChange={handleChange}
              company_user_role={user.company_user_role}
              company={user.company}
              equal={false}
              direction={user.Direction}
            />
          </div>
        )}
        <div className={classes.marginTop}>
          <Typography className={classes.label}>Prénom </Typography>
          <TextField
            id="outlined-basic"
            variant="outlined"
            className={classes.formInput}
            onChange={handleChange}
            name="firstname"
            value={user.firstname || ''}
            InputProps={{
              readOnly: !!(mode === FORM_MODE.readonly),
            }}
            error={!!errors.firstname}
          />
        </div>
        <div className={classes.marginTop}>
          <Typography className={classes.label}>Nom </Typography>

          <TextField
            id="outlined-basic"
            variant="outlined"
            className={classes.formInput}
            onChange={handleChange}
            name="lastname"
            value={user.lastname || ''}
            error={!!errors.lastname}
            InputProps={{
              readOnly: !!(mode === FORM_MODE.readonly),
            }}
          />
        </div>
        <div className={classes.marginTop}>
          <Typography className={classes.label}>Email </Typography>

          <TextField
            id="outlined-basic"
            variant="outlined"
            className={classes.formInput}
            onChange={handleChange}
            error={!!errors.email}
            InputProps={{
              readOnly: !!(mode === FORM_MODE.readonly),
            }}
            name="email"
            value={user.email || ''}
            helperText={errors.email ? 'Mauvais format' : ''}
          />
        </div>
        <div className={classes.marginTop}>
          <Typography className={classes.label}>
            Mot de passe
            <span
              style={{
                fontWeight: 'lighter',
                fontSize: 12,
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                fontStyle: 'italic',
              }}>
              ({generatePasswordOptionalText})
            </span>
          </Typography>
          <RandomPasswordSection
            password={user.generatedPassword}
            isCopied={isRandomPasswordCopied}
            generatePassword={generateRandomPassword}
            copyPassword={copyRandomPassword}
          />
        </div>
        {isConsultant && (
          <ServicesSection
            loading={servicesLoading}
            handleChange={handleChange}
            services={user.services}
          />
        )}

        {isConsultant && categorieTests && (
          <div style={{ marginTop: 30 }}>
            <MarginContainer>
              <Typography className={classes.bookChoiceLabel}>
                {' '}
                Catégories des tests mineurs autorisés{' '}
              </Typography>
            </MarginContainer>
            {sortBy(categorieTests, (el: any) => el?.attributes?.priority)?.map(
              (categoryTest: any) => (
                <div className={classes.bookChoiceContainer}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="name"
                        color="primary"
                        value={categoryTest?.id}
                        onChange={handleChangeCategory}
                        defaultChecked={user?.category_tests?.some(
                          (el: any) => el.id === categoryTest?.id
                        )}
                      />
                    }
                    label={categoryTest?.attributes?.name}
                  />
                </div>
              )
            )}
          </div>
        )}
        <div className={classes.marginTop}>
          <Grid container={true} direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item={true}>
              <Button className={classes.btnWhite} onClick={closeDrawer} disabled={loading}>
                <span className={classes.textHeader}>
                  {mode === FORM_MODE.readonly ? 'Retour' : 'Annuler'}
                </span>
              </Button>

              {mode !== FORM_MODE.readonly && (
                <Button
                  type="submit"
                  className={classes.btnPrimary}
                  disabled={loading}
                  data-cy="submit-create-edit-admin-benef">
                  <span className={classes.textHeader}>{btn()}</span>
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      </form>
      <OaPopin
        open={popin.open}
        title={popin.title}
        handleConfirm={handlePopinConfirm}
        handleClose={handlePopinClose}>
        <div className={classes.modalContent}>
          <Typography variant="h6" className={classes.modalText}>
            {popin.content}
          </Typography>
        </div>
      </OaPopin>
    </>
  );
};

export default ManagerForm;
