export const oaServices = [
  {
    id: 1,
    attributes: {
      name: 'Bilan de compétences',
    },
  },
  {
    id: 2,
    attributes: {
      name: "Bilan d'orientation",
    },
  },
  {
    id: 3,
    attributes: {
      name: 'Coaching',
    },
  },
  {
    id: 4,
    attributes: {
      name: 'Recrutement',
    },
  },
];

export const inCardService = [
  {
    id: 5,
    attributes: {
      name: 'A la carte',
    },
  },
];

export const formBaseInputs: string[] = ['firstname', 'lastname', 'email', 'phone'];

export const subordinatesIds = [2, 3, 4, 5];

export const generatePasswordOptionalText =
  "à n'utiliser qu'en cas d'échec de la procédure normale";

export const FORM_MODE = {
  create: 'create',
  update: 'update',
  readonly: 'readonly',
};

export const beneficiaryFields = ['gender', 'dayOfBirth', 'diploma', 'service'];
