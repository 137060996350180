/* eslint-disable react-hooks/exhaustive-deps */
import { useLanguage } from '@orientaction/hooks';
import { BarController, BarElement, Chart as ChartJS, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { fontFamily } from 'html2canvas/dist/types/css/property-descriptors/font-family';
import { FC, useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';

ChartJS.register(BarElement, ChartDataLabels, Legend, BarController);

interface IProps {
  data: any;
  typeTest?: any;
}

const Diagram: FC<IProps> = ({ data: propsData, typeTest }) => {
  const { language, translateWord } = useLanguage();
  const [graphLabels, setGraphLabels] = useState<Array<string>>([]);

  useEffect(() => {
    (async () => {
      const dataLabels =
        propsData?.length > 6
          ? propsData.map(async (obj: any) => (await translateWord(obj.label))[0])
          : propsData
              .concat(Array.from({ length: 6 - propsData.length }, () => ({ label: '', value: 0 })))
              .map(async (obj: any) => (await translateWord(obj.label))[0]);

      setGraphLabels(await Promise.all(dataLabels));
    })();
  }, [propsData, language]);

  const concatenedDataValues = useMemo(() => {
    return propsData
      .concat(Array.from({ length: 6 - propsData.length }, () => ({ label: '', value: 0 })))
      .map((obj: any) => obj.value);
  }, [propsData]);

  const color = [
    '#FDCE29',
    '#F7A82E',
    '#ED6637',
    '#C93646',
    '#942A59',
    '#662169',
    '#3E1877',
    '#0E0E87',
  ];

  const concatenedDataColor = useMemo(() => {
    return propsData
      .concat(Array.from({ length: 6 - propsData.length }, () => ({ label: '', value: 0 })))
      .map((obj: any, index: number) => {
        if (obj.codeColor) {
          return obj.codeColor;
        }
        return color[index];
      });
  }, [propsData]);

  const data = useMemo(() => {
    return {
      labels: graphLabels?.filter((label: any) => label !== ''),
      datasets: [
        {
          order: 1,
          data: concatenedDataValues,
          barThickness: graphLabels?.length > 8 ? 15 : 19,
          backgroundColor: typeTest === 5 ? concatenedDataColor : color,
        },
        {
          order: 1,
          data: [],
          backgroundColor: [
            '#FDCE29',
            '#F7A82E',
            '#ED6637',
            '#C93646',
            '#942A59',
            '#662169',
            '#3E1877',
            '#0E0E87',
          ],
        },
      ],
    };
  }, [graphLabels, concatenedDataValues]);

  useEffect(() => {
    console.log({ graphLabels, concatenedDataValues, data });
  }, [data, graphLabels, concatenedDataValues]);

  const getAnchor = (label: any) => {
    return concatenedDataValues[label.dataIndex] > 0 ? 'start' : 'end';
  };

  const getColor = (label: any) => {
    if (graphLabels[label.dataIndex]?.trim()?.length === 0) {
      return '#fff';
    }
    return concatenedDataValues[label.dataIndex] > 0 ? '#fff' : '#0E0E87';
  };

  const options = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    responsive: true,
    // maxBarThickness: 55,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        color: getColor,
        align: 'right',
        anchor: getAnchor,
        font: {
          size: 12,
          weight: 'bold',
          family: 'ITC Avant Garde Gothic Std Medium',
        },
        formatter(value: any) {
          return `${value} ${typeTest === 5 ? '' : '%'}`;
        },
      },
    },

    scales: {
      x: {
        max: typeTest === 5 ? concatenedDataValues[0] : 100,
        min: 0,
        position: 'bottom',
        display: false,
        grid: {
          display: true,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: '#0E1247',
          font: { size: 14, family: 'ITC Avant Garde Gothic Std Medium' },
          fontWeight: 500,
          mirror: true,
          z: 1,
          labelOffset: data?.labels?.length >= 9 ? -23 : data?.labels?.length === 8 ? -24 : -35,
        },
      },
    },
  };
  return (
    <>
      <Bar
        style={{ width: '100%', minHeight: 400 }}
        data={data as any}
        options={options as any}
        redraw={true}
      />
    </>
  );
};

export default Diagram;
