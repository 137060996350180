/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-return */
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { gameBookTemplateActions } from '@orientaction/api-actions';
import {
  BarEdition,
  DragList,
  ItemAdd,
  ItemPage,
  Layout,
  Model1,
  Model2,
  Model3,
  Model4,
  Model5,
  Model6,
  Model7,
  SimpleModal,
} from '@orientaction/components';
import { gameBookValues } from '@orientaction/reducers';
import { cloneDeep } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as Border } from '../../../Asset/svg/border.svg';
import { ReactComponent as Browser } from '../../../Asset/svg/browser.svg';
import { ReactComponent as Delete } from '../../../Asset/svg/delete.svg';
import { ReactComponent as Move } from '../../../Asset/svg/move.svg';
import { ReactComponent as Save } from '../../../Asset/svg/save.svg';
import { apiServiceNative } from '../../../utils/fetchNative';
import style from '../style';
import ChoiceModal from './choice-modal';
import { GameState, Page } from './game-model';
import Loader from './loader-edit';
import ModalCheckGame from './modal-checking-game';
import ModalEditGame from './modal-edit-game';

const EditPage = (props: any) => {
  const classes = style();
  const [game, setGame] = useState<any>([]);
  // const [game, setGame] = useState<GameState | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [textLoading, setTextLoading] = useState<string>('');
  const [pageToEdit, setPageToEdit] = useState<any>(null);
  const [indexPageToEdit, setIndexPageToEdit] = useState<number>(
    parseInt(sessionStorage.getItem('indexNew') ?? '0', 10)
  );
  const [interrationNull, setIterationNull] = useState<number>(1);
  const [isEditTitle, setIsEditTitle] = useState<boolean>(false);
  const [showEdition, setShowEdition] = useState<boolean>(false);
  const [stateEdition, setStateEdition] = useState<any>();
  const [styleEditor1, setStyleEditor1] = useState<any>();
  const [styleEditor2, setStyleEditor2] = useState<any>();
  const [styleEditor3, setStyleEditor3] = useState<any>();
  const [styleEditor4, setStyleEditor4] = useState<any>();
  const [warningsListData, setWarningList] = useState('');
  const [styleEditor5, setStyleEditor5] = useState<any>();
  const [styleEditor6, setStyleEditor6] = useState<any>();
  const [styleEditor7, setStyleEditor7] = useState<any>();
  const [styleEditor8, setStyleEditor8] = useState<any>();
  const [numberEditor, setNumberEditor] = useState<number>(0);
  const [pageData, setPageData] = useState<any>(1);
  const { id }: any = useParams();
  const [showLinkPopup1, setShowLinkPopup1] = useState<boolean>(false);
  const [showLinkPopup2, setShowLinkPopup2] = useState<boolean>(false);
  const [showLinkPopup3, setShowLinkPopup3] = useState<boolean>(false);
  const [showLinkPopup4, setShowLinkPopup4] = useState<boolean>(false);
  const [showLinkPopup5, setShowLinkPopup5] = useState<boolean>(false);
  const [showLinkPopup6, setShowLinkPopup6] = useState<boolean>(false);
  const [showLinkPopup7, setShowLinkPopup7] = useState<boolean>(false);
  const [showLinkPopup8, setShowLinkPopup8] = useState<boolean>(false);
  const [activeIndices, setActiveIndices] = useState([false, false, false]);
  const [checkEdit, setCheckEdit] = useState<boolean>(false);
  const [firstView, setFirtstView] = useState<boolean>(false);
  const [currentPage, setPage] = useState<any>(
    parseInt(sessionStorage.getItem('indexNew') ?? '0', 10) + 1
  );
  const [openGame, setOpenGame] = useState(false);
  const [lastCurrentPage, setLastCurrentPage] = useState<any>(1);
  const history = useHistory();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [deletingProcess, setDeletingProcess] = useState<boolean>(false);
  const dispatch = useDispatch();
  const refInput = useRef(null);

  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index: any) => {
    setActiveIndex(index);
  };

  const valuesBooksTemplate = useSelector(gameBookValues);
  const prevValuesBooksTemplateRef = useRef<Page>(valuesBooksTemplate);

  useEffect(() => {
    setFirtstView(true);
    if (checkEdit) {
      const prevValues = prevValuesBooksTemplateRef.current;
      Object.keys(valuesBooksTemplate).forEach(key => {
        const typedKey = key as keyof Page;

        if (valuesBooksTemplate[typedKey] !== prevValues[typedKey]) {
          handleChange(indexPageToEdit, typedKey, valuesBooksTemplate[typedKey]);
        }
      });

      prevValuesBooksTemplateRef.current = valuesBooksTemplate;
    }
    setCheckEdit(true);
  }, [checkEdit, indexPageToEdit, valuesBooksTemplate]);

  useEffect(() => {
    const fetchGameDetails = async () => {
      setLoading(true);
      try {
        const response = await apiServiceNative.get(
          `/api/details-game/${sessionStorage.getItem('id') || id}`
        );

        if (response?.id) {
          setGame(response);
          dispatch(gameBookTemplateActions.updateField('couvTemp', response?.couverture));
          dispatch(gameBookTemplateActions.updateField('title', response?.title));
          dispatch(gameBookTemplateActions.updateField('titlGame', response?.title));
          dispatch(gameBookTemplateActions.updateField('typeGame', response?.typeGame));
          dispatch(
            gameBookTemplateActions.updateField(
              'variables_restitution',
              response?.variables_restitution
            )
          );
          sessionStorage.setItem('gamePages', JSON.stringify(response?.pages));
          sessionStorage.setItem('gameVariables', JSON.stringify(response?.variables_restitution));
          const indexNew = parseInt(sessionStorage.getItem('indexNew') ?? '0', 10) as any;
          setIndexPageToEdit(indexNew);
          sessionStorage.setItem('lastIndex', indexNew);

          if (!sessionStorage.getItem('indexNew')) {
            setPageToEdit(response.pages[0]);
            dispatch(gameBookTemplateActions.setAllValues(response.pages[0]));
          } else {
            const pageToEditX = response.pages[indexNew];
            setPageToEdit({ ...pageToEdit, pageToEditX });
            setPageData(indexNew + 1);
            dispatch(gameBookTemplateActions.setAllValues({ ...pageToEdit, pageToEditX }));
            sessionStorage.removeItem('indexNew');
          }
        } else {
          console.error('Invalid response structure:', response);
        }
      } catch (error) {
        console.error('Error fetching game details:', error);
      } finally {
        setLoading(false);
      }
    };

    const handleFirstView = () => {
      sessionStorage.removeItem('gamePages');
      sessionStorage.removeItem('gameVariables');
      setGame(valuesBooksTemplate);
      console.log({ firstView: valuesBooksTemplate });
      if (!valuesBooksTemplate?.pages) {
        history.push('/admin/game/list');
        window.location.reload();
        return;
      }

      sessionStorage.setItem('gamePages', JSON.stringify(valuesBooksTemplate.pages));
      sessionStorage.setItem(
        'gameVariables',
        JSON.stringify(valuesBooksTemplate?.variables_restitution)
      );
      setFirtstView(true);
      const indexNew = parseInt(sessionStorage.getItem('indexNew') ?? '0', 10) as any;
      setIndexPageToEdit(indexNew);
      sessionStorage.setItem('lastIndex', indexNew);
      const pageToEditX = valuesBooksTemplate.pages[indexNew];
      setPageToEdit(pageToEditX);
      dispatch(gameBookTemplateActions.setAllValues(pageToEditX));
      setPageData(indexNew + 1);

      sessionStorage.removeItem('indexNew');
    };

    if (
      (sessionStorage.getItem('id') && !sessionStorage.getItem('firstView')) ||
      (id && !sessionStorage.getItem('firstView'))
    ) {
      fetchGameDetails();
      sessionStorage.setItem('firstView', 'true');
    } else {
      handleFirstView();
      sessionStorage.setItem('firstView', 'true');
    }
  }, [dispatch, id]);

  const handleChange = (pageIndex: number, field: keyof Page, value: any) => {
    setGame((prevState: GameState) => {
      const updatedPages = prevState.pages.map((page, index) => {
        if (index === pageIndex) {
          return { ...page, [field]: value };
        }
        return page;
      });
      sessionStorage.setItem('gamePages', JSON.stringify(updatedPages));
      return { ...prevState, pages: updatedPages };
    });
  };

  const removeStringIds = (array: any) => {
    return array.map((item: any) => {
      if (typeof item?.id === 'string') {
        delete item?.id;
      }
      return item;
    });
  };

  const maxPages = parseInt(game?.pages?.length || 0, 10);

  const renderModelCondition = (
    idModele: number,
    choices: any,
    choiceTitle: any,
    typeChoice: any,
    textEndGame?: any,
    displayCondition?: any
  ) => {
    switch (idModele) {
      case 1:
        return (
          <Model1
            handleActiveStyles={(data: any) => setStateEdition(data)}
            handleFocused={(isFocused: boolean, index: number) => {
              setShowEdition(isFocused);
              setNumberEditor(index);
            }}
            styleEditor1={styleEditor1}
            styleEditor2={styleEditor2}
            showLinkPopup1={showLinkPopup1}
            showLinkPopup2={showLinkPopup2}
            onChoiceClick={() => {
              setOpenhoice(true);
            }}
            choices={choices}
            choiceTitle={choiceTitle}
            typeChoice={typeChoice}
            textEndGame={textEndGame}
            displayCondition={displayCondition}
          />
        );
      case 2:
        return (
          <Model2
            handleActiveStyles={(data: any) => setStateEdition(data)}
            handleFocused={(isFocused: boolean, index: number) => {
              setShowEdition(isFocused);
              setNumberEditor(index);
            }}
            styleEditor3={styleEditor3}
            styleEditor4={styleEditor4}
            showLinkPopup3={showLinkPopup3}
            showLinkPopup4={showLinkPopup4}
            onChoiceClick={() => {
              setOpenhoice(true);
            }}
            choices={choices}
            choiceTitle={choiceTitle}
            typeChoice={typeChoice}
            textEndGame={textEndGame}
            displayCondition={displayCondition}
          />
        );
      case 3:
        return (
          <Model3
            handleActiveStyles={(data: any) => setStateEdition(data)}
            handleFocused={(isFocused: boolean, index: number) => {
              setShowEdition(isFocused);
              setNumberEditor(index);
            }}
            styleEditor5={styleEditor5}
            styleEditor6={styleEditor6}
            showLinkPopup5={showLinkPopup5}
            showLinkPopup6={showLinkPopup6}
            onChoiceClick={() => {
              setOpenhoice(true);
            }}
            choices={choices}
            choiceTitle={choiceTitle}
            typeChoice={typeChoice}
            textEndGame={textEndGame}
            displayCondition={displayCondition}
          />
        );
      case 4:
        return (
          <Model4
            handleActiveStyles={(data: any) => setStateEdition(data)}
            handleFocused={(isFocused: boolean, index: number) => {
              setShowEdition(isFocused);
              setNumberEditor(index);
            }}
            styleEditor7={styleEditor7}
            showLinkPopup7={showLinkPopup7}
            onChoiceClick={() => {
              setOpenhoice(true);
            }}
            choices={choices}
            choiceTitle={choiceTitle}
            typeChoice={typeChoice}
            textEndGame={textEndGame}
            displayCondition={displayCondition}
          />
        );
      case 5:
        return (
          <Model5
            handleActiveStyles={(data: any) => setStateEdition(data)}
            handleFocused={(isFocused: boolean, index: number) => {
              setShowEdition(isFocused);
              setNumberEditor(index);
            }}
            styleEditor8={styleEditor8}
            showLinkPopup8={showLinkPopup8}
            onChoiceClick={() => {
              setOpenhoice(true);
            }}
            choices={choices}
            choiceTitle={choiceTitle}
            typeChoice={typeChoice}
            textEndGame={textEndGame}
            displayCondition={displayCondition}
          />
        );
      case 6:
        return (
          <Model6
            onChoiceClick={() => {
              setOpenhoice(true);
            }}
            choices={choices}
            choiceTitle={choiceTitle}
            typeChoice={typeChoice}
            textEndGame={textEndGame}
            displayCondition={displayCondition}
          />
        );
      case 7:
        return (
          <Model7
            onChoiceClick={() => {
              setOpenhoice(true);
            }}
            choices={choices}
            choiceTitle={choiceTitle}
            typeChoice={typeChoice}
            textEndGame={textEndGame}
            displayCondition={displayCondition}
          />
        );
      default:
        return;
    }
  };

  const editStyleEditor = (styleCommand: any, arg: any) => {
    switch (numberEditor) {
      // For model1
      case 1:
        return setStyleEditor1({ styleCommand, arg });

      case 2:
        return setStyleEditor2({ styleCommand, arg });

      // For model2
      case 3:
        return setStyleEditor3({ styleCommand, arg });

      case 4:
        return setStyleEditor4({ styleCommand, arg });

      // For model3
      case 5:
        return setStyleEditor5({ styleCommand, arg });

      case 6:
        return setStyleEditor6({ styleCommand, arg });

      // For model4
      case 7:
        return setStyleEditor7({ styleCommand, arg });

      // For model5
      case 8:
        return setStyleEditor8({ styleCommand, arg });

      default:
        return;
    }
  };
  const editPopup = (show: boolean) => {
    switch (numberEditor) {
      // For model1
      case 1:
        return setShowLinkPopup1(show);

      case 2:
        return setShowLinkPopup2(show);

      // For model2
      case 3:
        return setShowLinkPopup3(show);
      case 4:
        return setShowLinkPopup4(show);

      // For model3
      case 5:
        return setShowLinkPopup5(show);

      case 6:
        return setShowLinkPopup6(show);

      // For model4
      case 7:
        return setShowLinkPopup7(show);

      // For model5
      case 8:
        return setShowLinkPopup8(show);

      default:
        return;
    }
  };

  const handleGamePage = (pages: any, inde: any) => {
    // setPageData(inde);
    setGame({ ...game, pages });
    sessionStorage.setItem('lastIndex', (inde - 1).toString());
    dispatch(gameBookTemplateActions.setAllValues({ ...game, pages }));
    setIndexPageToEdit(inde - 1);
    setPageToEdit(pages[inde - 1]);
    dispatch(gameBookTemplateActions.setAllValues(pages[inde - 1]));
  };

  function removePagesField(array: any) {
    return array.map((obj: any) => {
      const { pages, ...rest } = obj;
      return rest;
    });
  }
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      // handleSave();
      setIsEditTitle(false);
    }
  };
  const saveTitle = () => {
    setIsEditTitle(!isEditTitle);
    /* if (isEdit) {
      handleSave();
    } */
  };

  useEffect(() => {
    const refI: any = refInput?.current;

    if (isEditTitle && refI) {
      refI.click();
    }
  }, [isEditTitle]);

  function moveElementInArray<T>(array: T[], indexToMove: number, indexFinale: number): T[] {
    if (
      indexToMove < 0 ||
      indexToMove >= array.length ||
      indexFinale < 0 ||
      indexFinale >= array.length
    ) {
      throw new Error('Invalid indices');
    }

    const [element] = array.splice(indexToMove, 1);

    array.splice(indexFinale, 0, element);

    return array;
  }
  const changePageBarEdition = (page: number, currenpage?: any, newPage?: any) => {
    if (!currenpage && !newPage) {
      if (currentPage !== '') {
        moveElementInArray(game.pages, currentPage - 1, page - 1);
        setPageToEdit({
          ...game?.pages[page - 1],
          tempImage: game?.pages[page - 1]?.tempImage || null,
        });
        dispatch(
          gameBookTemplateActions.setAllValues({
            ...game?.pages[page - 1],
            tempImage: game?.pages[page - 1]?.tempImage || null,
          })
        );
        setIndexPageToEdit(page - 1);
        sessionStorage.setItem('lastIndex', (page - 1) as any);
        setLastCurrentPage(page);
      } else {
        moveElementInArray(game.pages, lastCurrentPage - 1, page - 1);
        setPageToEdit({
          ...game?.pages[page - 1],
          tempImage: game?.pages[page - 1]?.tempImage || null,
        });
        dispatch(
          gameBookTemplateActions.setAllValues({
            ...game?.pages[page - 1],
            tempImage: game?.pages[page - 1]?.tempImage || null,
          })
        );
        setIndexPageToEdit(page - 1);
        sessionStorage.setItem('lastIndex', (page - 1) as any);
        setLastCurrentPage(page);
      }
    }
  };

  const [open, setOpen] = useState(false);
  const [openExit, setOpenExit] = useState(false);

  const [openChoice, setOpenhoice] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenExit(false);
    setActiveIndex(null);
  };

  const handleConfirm = () => {
    setActiveIndex(null);
    if (openExit) {
      setActiveIndex(null);
      setOpenExit(false);
      setOpenExit(false);
      history.push(`/admin/game/list`);
    } else {
      handleSave(true);
      setOpen(false);
    }
  };

  const handleCancel = () => {
    // handle cancel action

    setOpen(false);
    setOpenExit(false);
    setActiveIndex(null);
  };

  const handleChangeMultiple = (pageI: number, data: any) => {
    const fieldsToUpdate = ['typeChoice', 'choiceTitle', 'choice'];
    fieldsToUpdate.forEach((field: any) => {
      handleChange(pageI, field, data[field]);
    });
  };

  const handleInputChange = (e: any) => {
    const value = e.target.value.trim();

    const numericValue = parseInt(value, 10);

    if (value === '' || (!isNaN(numericValue) && numericValue >= 1 && numericValue <= maxPages)) {
      setPage(value === '' ? '' : numericValue);
      if (value !== '' && !isNaN(numericValue)) {
        changePageBarEdition(value === '' ? '' : (numericValue as number as any));
      }
    } else if (numericValue > maxPages) {
      setPage(maxPages - 1);

      changePageBarEdition(maxPages);
    }
  };

  const handleChoice = (data?: any, isEdit?: boolean) => {
    setOpenhoice(false);
    if (isEdit) {
      const updatedPages = game.pages.map((page: any, index: number) => {
        if (index === indexPageToEdit) {
          const updatedPage = {
            id: page?.id,
            p1: page?.p1,
            p2: page?.p2,
            title: page?.title,
            typeChoice: data.typeChoice,
            choiceTitle: data.choiceTitle,
            choice: data.choice,
            textEndGame: data.textEndGame,
            template_game: page?.template_game,
            citationContent1: page?.citationContent1,
            citationAuthor1: page?.citationAuthor1,
            tempImage: page?.tempImage,

            citationContent2: page?.citationContent2,
            citationAuthor2: page?.citationAuthor2,
            informationEncyclopedia1: page?.informationEncyclopedia1,
            pEncyclopedia1: page?.pEncyclopedia1,
            informationEncyclopedia2: page?.informationEncyclopedia2,
            pEncyclopedia2: page?.pEncyclopedia2,
            choiceDisplay: data?.choiceDisplay,
            contentForType1: page?.contentForType1,
            contentForType2: page?.contentForType2,
            iSmainPath: page.iSmainPath,
          } as any;

          if (page.files) {
            updatedPage.files = page.files;
          }
          if (page?.illustration?.id) {
            updatedPage.illustration = {
              id: page?.illustration?.id,
              url: page?.illustration?.url,
            };
          }

          return updatedPage;
        }

        return page;
      });

      dispatch(gameBookTemplateActions.setAllValues(updatedPages[indexPageToEdit]));
      setPageToEdit(updatedPages[indexPageToEdit]);

      // handleSaveChoice(updatedPages, indexPageToEdit);
    } else {
      console.log('noedit');
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      const message = 'Êtes-vous sûr de vouloir quitter sans sauvegarder vos modifications ?';
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const deletePage = () => {
    const pagesData = cloneDeep([...game?.pages]);
    const indexPage = currentPage - 1;
    pagesData.splice(indexPage, 1);
    setPage(indexPage === 0 ? 1 : indexPage);
    setLastCurrentPage(indexPage === 0 ? 1 : indexPage);
    setPageToEdit(pagesData[indexPage === 0 ? 0 : indexPage - 1]);
    setIndexPageToEdit(indexPage === 0 ? 0 : indexPage - 1);
    sessionStorage.setItem('lastIndex', (indexPage === 0 ? 0 : indexPage - 1) as any);
    dispatch(
      gameBookTemplateActions.setAllValues(game?.pages[indexPage === 0 ? 1 : indexPage - 1])
    );
    setGame({ ...game, pages: pagesData });

    sessionStorage.setItem('gamePages', JSON.stringify(pagesData));

    setOpenModal(false);
    // setDeletingProcess(true);
    // setIndexPageToEdit(indexPageToEdit === 0 ? 0 : indexPageToEdit - 1);
  };

  useEffect(() => {
    if (deletingProcess) {
      handleSave();
      setDeletingProcess(false);
    }
  }, [game, deletingProcess]);

  const buildFormData = (gameX: any, valuesBooksTemplateX: any) => {
    const payload = removePagesField(gameX.pages);
    payload.createdFrom = 'front_user';
    const formData = new FormData();

    gameX.pages.forEach((page: any, index: any) => {
      if (page.files) {
        formData.append(`file.img${index}`, page.files);
      }
    });
    formData.append('createdFrom', 'front_user');
    const title = gameX?.titlGame || gameX?.title || valuesBooksTemplateX?.titlGame;
    if (title) {
      formData.append('title', title);
    }

    if (valuesBooksTemplateX['file.img']) {
      formData.append('file.img', valuesBooksTemplateX['file.img']);
    }

    formData.append('pagesList', JSON.stringify(removeStringIds(payload)));
    formData.append(
      'variables_restitution',
      JSON.stringify(
        gameX?.variables_restitution && gameX?.variables_restitution.length > 0
          ? gameX?.variables_restitution
          : []
      )
    );
    formData.append('typeGame', gameX?.typeGame || 'classic');

    return formData;
  };

  const createGame = async (formData: any) => {
    try {
      const response = await apiServiceNative.post(`/api/create-serious-game`, formData, true);
      return response;
    } catch (error) {
      console.error('Erreur lors de la création du jeu :', error);
      throw error;
    }
  };

  const updateGame = async (idx: any, formData: any) => {
    try {
      const response = await apiServiceNative.put(`/api/update-game/${idx}`, formData, true);
      return response;
    } catch (error) {
      console.error('UPDATE GAME :', error);
      throw error;
    }
  };

  const updateCouve = async (idx: any, formData: any) => {
    sessionStorage.setItem('id', idx);
    try {
      const response = await apiServiceNative.put(`/api/update-game-cover/${idx}`, formData, true);

      return response;
    } catch (error) {
      console.error('Erreur lors de la mise à jour du jeu :', error);
      throw error;
    }
  };

  const updateGameStatus = async (idX: any, isPublish: any) => {
    try {
      await apiServiceNative.put(`/api/change-status-game/${idX}`, {
        publishedAt: !!isPublish,
      });
    } catch (error) {
      console.error('Erreur lors de la mise à jour du statut du jeu :', error);
      throw error;
    }
  };

  const handleSave = async (isPublish?: boolean) => {
    setLoading(true);

    try {
      const formData = buildFormData(game, valuesBooksTemplate);
      console.log('Après buildFormData', formData);
      setTextLoading(isPublish ? 'Publication en cours ...' : 'Sauvegarde en cours ...');
      let response;
      if (sessionStorage.getItem('id')) {
        await updateGameStatus(sessionStorage.getItem('id'), isPublish);
        await updateCouve(sessionStorage.getItem('id'), formData);
        response = await updateGame(sessionStorage.getItem('id'), formData);
      } else {
        const idsPages = game?.pages.map((res: any) => res.id);
        const idsPagesString = idsPages.join(',');
        const idsVariables = game?.variables_restitution?.map((res: any) => res.id) || [];
        const idsVariablesString = idsVariables?.join(',');
        const idNews = await apiServiceNative.get(
          idsVariables && idsVariables.length > 0
            ? `/api/get-id-game?pageId=${idsPagesString}&variableIds=${idsVariablesString}`
            : `/api/get-id-game?pageId=${idsPagesString}`
        );

        await updateCouve(idNews, formData);
        response = await updateGame(idNews, formData);

        console.log('Après createGame', response);
      }

      setLoading(false);
      setActiveIndex(null);

      if (response) {
        if (isPublish) {
          if (validateArrayObjects(response?.pages)?.length > 0) {
            setOpenGame(true);
            setWarningList(validateArrayObjects(response?.pages) as any);
          }
        }
        setGame(response);
        dispatch(gameBookTemplateActions.setAllValues(response[indexPageToEdit]));
        dispatch(
          gameBookTemplateActions.updateField(
            'variables_restitution',
            response?.variables_restitution
          )
        );
        if (response.id) {
          sessionStorage.setItem('id', response.id);
        }
        setPageToEdit(response.pages[indexPageToEdit]);
        setIndexPageToEdit(indexPageToEdit);
        sessionStorage.removeItem('idPagesTemps');
        localStorage.removeItem('idFILESTemps');
        sessionStorage.removeItem('idVariablesTemps');
        sessionStorage.setItem('gamePages', JSON.stringify(response?.pages));
        sessionStorage.setItem('gameVariables', JSON.stringify(response?.variables_restitution));
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour du jeu :', error);
      setLoading(false);
      setActiveIndex(null);
    }
  };

  const validateArrayObjects = (array: any): any => {
    const errors: any[] = [];
    let hasEndGame = false;
    const validIds = array.map((item: any) => item.id);

    array.forEach((item: any, index: any) => {
      if (item.typeChoice === 'end_game') {
        hasEndGame = true;
      } else if (!item.choice || item.choice.length === 0) {
        errors.push(
          `Erreur dans la page intitulée "${item.title || `P${index + 1}`}": Le choix est vide.`
        );
      } else {
        item.choice.forEach((choice: any, choiceIndex: any) => {
          if (!choice.destination && !choice.destinationUrl) {
            errors.push(
              `Erreur dans la page intitulée "${item.title || `P${index + 1}`}", choix numéro ${
                choiceIndex + 1
              }: Destination et destinationUrl sont manquants.`
            );
          }
          if (choice.destination !== -1) {
            if (choice.destination && !validIds.includes(choice.destination)) {
              errors.push(
                `Erreur dans la page intitulée "${item.title || `P${index + 1}`}", choix numéro ${
                  choiceIndex + 1
                }: La page de destination ${choice.destination} n'est pas trouvée dans la liste.`
              );
            }
          }
        });
      }
    });

    if (!hasEndGame) {
      errors.push("Ce jeu n'a pas de fin.");
    }

    return errors;
  };

  return (
    <Layout isaddBackground={true} showOverFlow={true}>
      <ChoiceModal
        open={openChoice}
        onClose={handleChoice}
        pageData={pageToEdit || {}}
        indexPageToEdit={indexPageToEdit}
        typeGame={game?.typeGame}
      />
      <ModalCheckGame
        open={openGame}
        onCancel={() => {
          setOpenGame(false);
        }}
        handleClose={() => {
          setOpenGame(false);
        }}
        title="Attention le jeu comporte plusieurs erreurs"
        message=""
        warningsListData={warningsListData as any}
      />

      <ModalEditGame
        open={open || openExit}
        handleClose={handleClose}
        title={game?.titlGame || game?.title || valuesBooksTemplate?.titlGame}
        message={
          open
            ? 'Vous êtes sur le point de publier le jeu'
            : "Êtes-vous sûr de vouloir quitter l'édition du jeu ?"
        }
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
      {/* <ModalCheckingGame
        open={true}
        handleClose={handleClose}
        title={game?.titlGame || game?.title || valuesBooksTemplate?.titlGame}
        message={
          open
            ? 'Vous êtes sur le point de publier le jeu'
            : "Êtes-vous sûr de vouloir quitter l'édition du jeu ?"
        }
        onCancel={handleCancel}
      /> */}
      <Loader open={loading} text={textLoading} />

      <>
        <Grid container={true}>
          <Grid item={true} sm={2} className={classes.section1}>
            <div className={classes.inner}>
              <div
                className={`${classes.wrapbutton} ${activeIndex === 0 ? 'active' : ''}`}
                // onMouseEnter={() => handleMouseEnter(0)}
                // onMouseLeave={() => handleMouseLeave(0)}
              >
                <IconButton
                  aria-label="delete"
                  className={classes.iconButton}
                  onClick={() => {
                    handleClick(0);
                    setOpenExit(true);
                  }}>
                  <Move className={classes.icon} />
                </IconButton>
                <div className={classes.tooltipLabel}>Quitter sans sauvegarder</div>
              </div>
              <div
                className={`${classes.wrapbutton} ${activeIndex === 1 ? 'active' : ''}`}
                // onMouseEnter={() => handleMouseEnter(1)}
                // onMouseLeave={() => handleMouseLeave(1)}
              >
                <IconButton
                  aria-label="save"
                  onClick={() => {
                    handleClick(1);
                    handleSave();
                  }}
                  className={classes.iconButton}>
                  <Save className={classes.icon} />
                </IconButton>
                <div className={classes.tooltipLabel}>Sauvegarder comme brouillon</div>
              </div>
              <div
                className={`${classes.wrapbutton} ${activeIndex === 2 ? 'active' : ''}`}
                //  onMouseEnter={() => handleMouseEnter(2)}
                // onMouseLeave={() => handleMouseLeave(2)}
              >
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    handleClick(2);
                    setOpen(true);
                  }}
                  className={classes.iconButton}>
                  <Browser className={classes.icon} />
                </IconButton>
                <div className={classes.tooltipLabel}>Publier</div>
              </div>
            </div>
          </Grid>
          <Grid item={true} sm={10} className={classes.section2}>
            <div style={{ padding: '10px 30px', width: '100%' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {!showEdition && (
                  <Grid container={true}>
                    <Grid item={true} sm={3} className={classes.containerGrid}>
                      {isEditTitle && game?.pages ? (
                        <TextField
                          ref={refInput}
                          variant="outlined"
                          className={classes.formInput}
                          name="test-name"
                          value={game?.pages[indexPageToEdit]?.title}
                          onChange={e => handleChange(indexPageToEdit, 'title', e.target.value)}
                          placeholder="Titre de la page"
                          onBlur={saveTitle}
                          onKeyDown={handleKeyDown}
                          autoFocus={true}
                        />
                      ) : (
                        <>
                          {game?.pages && (
                            <Typography variant="h4" className={classes.title2}>
                              {game?.pages[indexPageToEdit]?.title ||
                                `Page sans titre (${interrationNull})`}{' '}
                            </Typography>
                          )}
                        </>
                      )}
                      <IconButton
                        aria-label="delete"
                        style={{ marginLeft: 15 }}
                        onClick={saveTitle}>
                        <Border className={classes.icon} />
                      </IconButton>
                    </Grid>
                    <Grid item={true} sm="auto" className={classes.containerGrid}>
                      <div className={classes.containerPageNumber}>
                        <Typography variant="h4" className={classes.title2}>
                          Position dans le sommaire
                        </Typography>
                        <TextField
                          variant="outlined"
                          className={classes.formInputNumber}
                          name="test-name"
                          type="text"
                          inputProps={{ min: 1, max: maxPages }}
                          value={currentPage}
                          onChange={handleInputChange}
                        />
                        <div className={classes.containerIconS}>
                          <KeyboardArrowUp
                            onClick={() => {
                              if (currentPage > 1) {
                                setPage(currentPage - 1);
                                setLastCurrentPage(currentPage - 1);
                                changePageBarEdition(currentPage - 1);
                              }
                            }}
                            className={classes.iconS}
                          />
                          <KeyboardArrowDown
                            onClick={() => {
                              if (currentPage < parseInt(game?.pages?.length || 0, 10)) {
                                setPage(currentPage + 1);
                                setLastCurrentPage(currentPage + 1);
                                changePageBarEdition(currentPage + 1);
                              }
                            }}
                            className={classes.iconS}
                          />
                        </div>
                      </div>
                    </Grid>

                    <Grid item={true} sm="auto" className={classes.containerGrid}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={e => {
                              handleChange(
                                indexPageToEdit,
                                'iSmainPath',
                                !game?.pages[indexPageToEdit]?.iSmainPath
                              );
                            }}
                            color="primary"
                            checked={game?.pages ? game?.pages[indexPageToEdit]?.iSmainPath : false}
                          />
                        }
                        label={
                          <Typography variant="h4" className={classes.title2}>
                            Chemin principal
                          </Typography>
                        }
                        style={{ marginLeft: 40 }}
                      />
                    </Grid>
                    <Grid item={true} sm="auto" className={classes.containerGrid}>
                      <Button
                        color="primary"
                        style={{ marginLeft: 40 }}
                        startIcon={<Delete className={classes.iconbig} />}
                        onClick={() => setOpenModal(true)}>
                        <Typography variant="h4" className={classes.title2}>
                          Supprimer la page
                        </Typography>
                      </Button>
                      <SimpleModal
                        open={openModal}
                        handleClose={() => setOpenModal(false)}
                        title="Information"
                        message="Êtes vous sur de vouloir supprimer cette page ?"
                        textClick="Oui"
                        textClose="Non"
                        handleClick={deletePage}
                      />
                    </Grid>
                  </Grid>
                )}
                {showEdition && (
                  <BarEdition
                    stateEdition={stateEdition}
                    editState={editStyleEditor}
                    changePageBarEdition={changePageBarEdition}
                    maxPage={game?.pages?.length || 0}
                    pageData={pageData}
                    handleLink={editPopup}
                  />
                )}
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container={true} style={{ background: '#F1F1F1' }}>
          {/* Debut Sommaire contextuel */}
          <Grid
            item={true}
            sm={2}
            className={classes.section3}
            onClick={() => setShowEdition(false)}>
            <ItemPage
              id={game?.id}
              title={game?.titlGame || game?.title || valuesBooksTemplate?.titlGame}
              url={game?.couvTemp || game?.couverture || valuesBooksTemplate?.couvTemp}
              isCover={true}
              handleClick={() => {
                dispatch(
                  gameBookTemplateActions.updateField(
                    'titlGame',
                    game?.titlGame || game?.title || valuesBooksTemplate?.titlGame
                  )
                );
                dispatch(
                  gameBookTemplateActions.updateField(
                    'couvTemp',
                    game?.couvTemp || game.couverture || valuesBooksTemplate?.couvTemp
                  )
                );
                dispatch(gameBookTemplateActions.updateField('typeGame', game?.typeGame));
                dispatch(
                  gameBookTemplateActions.updateField(
                    'variables_restitution',
                    game?.variables_restitution || valuesBooksTemplate?.variables_restitution
                  )
                );
                history.push(`/admin/game/edit/the-game`);
              }}
            />

            <div style={{ padding: '0px 15px', height: '75vh', overflowY: 'auto' }}>
              {game?.pages?.length === 0 && <ItemAdd id={id} position={0} gameData={game} />}
              <DragList
                idGame={id}
                initialItems={game?.pages}
                handlePageToEdit={(page: any, i: number | undefined) => {
                  dispatch(
                    gameBookTemplateActions.setAllValues({
                      ...page,
                      tempImage: page?.tempImage || null,
                    })
                  );
                  setPageToEdit({ ...page, tempImage: page?.tempImage || null });
                  setIterationNull(i as number);
                  setShowEdition(false);
                }}
                handleIndex={(index: number) => {
                  setIndexPageToEdit(index);
                  setPage(index + 1);
                  setLastCurrentPage(index + 1);
                  sessionStorage.setItem('lastIndex', index.toString());
                  setPageData(index + 1);
                }}
                pageSelected={pageToEdit}
                handleGamePage={handleGamePage}
              />
              {game?.pages?.length > 0 && (
                <ItemAdd
                  id={id}
                  position={game && game?.pages ? parseInt(game?.pages?.length, 10) : 0}
                  gameData={game}
                />
              )}
            </div>
          </Grid>{' '}
          {/* Fin Sommaire contextuel */}
          {game?.pages && game?.pages?.length > 0 && (
            <Grid
              item={true}
              sm={10}
              className={classes.section4}
              onClick={e => {
                e.preventDefault();
                if (window?.getSelection()?.toString() === '') {
                  setShowEdition(false);
                }
                setIsEditTitle(false);
              }}>
              {renderModelCondition(
                valuesBooksTemplate?.template_game || 1,
                valuesBooksTemplate?.choice || '',
                valuesBooksTemplate?.choiceTitle || '',
                valuesBooksTemplate?.typeChoice || '',
                valuesBooksTemplate?.textEndGame || '',
                valuesBooksTemplate?.choiceDisplay || ''
              )}
            </Grid>
          )}
        </Grid>
      </>
    </Layout>
  );
};

export default EditPage;
