/* eslint-disable react/no-danger */
/* eslint-disable react/button-has-type */
/* eslint-disable prettier/prettier */
import { Button, Grid, IconButton } from '@material-ui/core';
import { useState } from 'react';
import { ReactComponent as Arrow } from '../../../../../Asset/svg/arrow_right.svg';
import { ReactComponent as Check } from '../../../../../Asset/svg/check.svg';
import { ReactComponent as ZoomIn } from '../../../../../Asset/svg/zoom_in.svg';
import ItemChoice from '../../ItemChoice';
import ItemCitation from '../../ItemCitation';
import ItemEncyclopedie from '../../ItemEncyclopedie';
import ModalZoomImg from '../../ModelZoomImg';
import style from '../style';

const Model1 = (props: any) => {
  const { page, handleChooseDestion, endGame, handleChooseVariable, endGameVariable, typeGame } = props;
  const classes = style();
  const [selectedChoice, setSelectedChoice] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);

  const chooseChoice = (elemToChoose: any) => {
    setSelectedChoice(elemToChoose);
    if (page.typeChoice === 'redirection') {
      window.open(elemToChoose.destinationUrl, '_blank');
    }
  };

  const isSelectedData = (elem: any) => {
    return elem.id === selectedChoice.id;
  };

  const goToDestination = () => {
    if (handleChooseDestion && selectedChoice.id) {
      console.log('redirection');
      handleChooseDestion(selectedChoice.destination);
      if (selectedChoice.selectedVariable && handleChooseVariable) {
        handleChooseVariable(selectedChoice.selectedVariable)
      }
    }
  };

  const goToDestinationDirect = (idDestination: any, idVariableSelected: any) => {
    if (handleChooseDestion) {
      handleChooseDestion(idDestination);
      if (handleChooseVariable) {
        handleChooseVariable(idVariableSelected)
      }
    }
  };


  return (
    <div className={classes.container} onClick={(e) => e.stopPropagation()}>
      <Grid className={classes.containerGrid} container={true} style={{ height: '100%' }}>
        <Grid
          item={true}
          sm={12}
          className={classes.blockContentParent}
        >
          <div className={classes.blockContent}>
            <IconButton
              color="secondary"
              aria-label="delete"
              style={{ background: '#000A8C' }}
              className={classes.btnFloat}
              onClick={() => setOpen(true)}
            >
              <ZoomIn className={classes.icon} />
            </IconButton>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={page?.illustration?.url} alt="page covurture" />
            </div>
            <ModalZoomImg
              open={open}
              fileUrl={page?.illustration?.url}
              handleClose={() => setOpen(false)}
            />
          </div>
          <div className={classes.blockContent} style={{paddingTop: 0}}>
            {page?.contentForType2 === 'normal' && (
              <div
                className={`${classes.blockContentTextNormal} editor`}
                dangerouslySetInnerHTML={{ __html: page?.p2 }}
              />
            )}
            {page?.contentForType2 === 'citation' && (
              <ItemCitation text={page?.citationContent2} autor={page?.citationAuthor2} />
            )}
            {page?.contentForType2 === 'encyclopedia' && (
              <ItemEncyclopedie text={page.informationEncyclopedia2} />
            )}
          </div>
        </Grid>
        <Grid item={true} sm={12} className={classes.blockContentParent}>
          <div
            className={classes.blockContent}
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <div className={classes.blockText2}>
              {page?.contentForType1 === 'normal' && (
                <div
                  className={`${classes.blockContentTextNormal} editor`}
                  dangerouslySetInnerHTML={{ __html: page?.p1 }}
                />
              )}
              {page?.contentForType1 === 'citation' && (
                <ItemCitation text={page.citationContent1} autor={page.citationAuthor1} />
              )}
              {page?.contentForType1 === 'encyclopedia' && (
                <ItemEncyclopedie text={page.informationEncyclopedia1} />
              )}
            </div>
          </div>
          <div
            className={classes.blockContent}
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            {page?.choiceTitle && page?.choiceTitle !== '' && (
              <div className={classes.choiceTitle}>{page?.choiceTitle}</div>
            )}
            {page?.typeChoice !== 'end_game' &&
              page?.typeChoice !== 'pursue' &&
              page?.choice?.map((elem: any, index: number) => (
                <div
                  style={{ marginBottom: index >= 1 ? 20 : 10 }}
                  onClick={() => chooseChoice(elem)}
                >
                  <ItemChoice
                    id={elem.id}
                    number={index + 1}
                    isSelected={isSelectedData(elem)}
                    title={elem.text}
                  />
                </div>
              ))}
            {page?.typeChoice === 'multiples' && (
              <div className={classes.btnValider}>
                <Button
                  size="small"
                  color="primary"
                  disableRipple={true}
                  className={classes.btn}
                  style={{ background: '#000A8C' }}
                  onClick={goToDestination}
                  endIcon={<Check className={classes.btnicon} />}
                >
                  Valider mon choix
                </Button>
              </div>
            )}
            {page?.typeChoice === 'end_game' && (
              <div
                className={classes.btnEnd}
                style={page?.choiceDisplay === 'carre' ? { justifyContent: 'center' } : {}}
              >
                <div className={classes.choiceEndGame}>
                  {typeGame === 'game' ? 'Le test est terminé' : <>Ceci est la fin du jeu. <br /> Merci.</> }
                </div>
                <Button
                  size="small"
                  color="primary"
                  disableRipple={true}
                  className={classes.btn}
                  style={{ background: '#000A8C', height: 'auto' }}
                  onClick={endGame}
                  endIcon={<Arrow className={classes.btnicon} />}
                >
                  {page?.textEndGame}
                </Button>
              </div>
            )}
            {page?.typeChoice === 'pursue' && (
              <div className={classes.btnPursue}>
                <Button
                  size="small"
                  color="primary"
                  disableRipple={true}
                  className={classes.btnPursueInside}
                  style={{ background: '#000A8C' }}
                  onClick={() => goToDestinationDirect(page?.choice[0]?.destination, page?.choice[0]?.selectedVariable)}
                  endIcon={<Arrow className={classes.btnicon} />}
                >
                  <span dangerouslySetInnerHTML={{ __html: page?.choice[0]?.text }} />
                </Button>
              </div>
            )}
            {page?.isVariable && (
              <div className={classes.btnPursue}>
                <Button
                  size="small"
                  color="primary"
                  disableRipple={true}
                  className={classes.btnPursueInside}
                  style={{ background: '#000A8C', padding: 20, fontSize: 16 }}
                  onClick={endGameVariable}
                  endIcon={<Arrow className={classes.btnicon} />}
                >
                  Retour à la liste des ressources
                </Button>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Model1;
