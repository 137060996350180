import { IconButton, Theme, useMediaQuery } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ApiActions } from '@orientaction/api-actions';
import { ProfileMenu, UserPhotos } from '@orientaction/commons';
import { useAlert, useProfileMenu } from '@orientaction/hooks';
import { appLanguage } from '@orientaction/reducers';
import { userService } from '@orientaction/services';
import { getFromLS, isAdmin, publicLogoPath } from '@orientaction/utils';
import clsx from 'clsx';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import style from './style';

interface IProps {
  children: any;
  noPadding?: boolean;
  showLogo?: boolean;
  isWhite?: boolean;
  toBack?: string;
  showToplines?: boolean;
  showBottomLines?: boolean;
  isaddBackground?: boolean;
  isGradient?: boolean;
  isNew?: boolean;
  isNew2?: boolean;
  isTransparent?: boolean;
  index?: number;
  typeScroll?: string;
  isNew3?: boolean;
}

const LayoutResultat: FC<IProps> = ({
  children,
  noPadding,
  showLogo,
  isWhite,
  toBack = null,
  showToplines = true,
  showBottomLines = true,
  isaddBackground = true,
  isGradient,
  isTransparent,
  index,
  typeScroll = 'sticky',
  isNew3 = false
}: any) => {
  const history = useHistory();
  const alert = useAlert();

  const cls = style();

  const user = getFromLS('userInfos') ? JSON.parse(getFromLS('userInfos') || '') : undefined;

  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  const dispatch = useDispatch();

  const { open, anchorRef, handleToggle, handleClose, goToProfileEdit, logout } = useProfileMenu({
    user,
  });

  const mainLink = isAdmin(user) ? '/admin/users-resultats' : '/main';
  const lang: string = useSelector(appLanguage);

  const redirect = () => {
    if (toBack) {
      goBackRessource();
      return;
    }

    history.goBack();
  };

  const goBackRessource = () => {
    if (xsDown && toBack === '/mes-ressources') {
      sessionStorage.setItem('redirect', JSON.stringify(['ebooks', index]));
    }
    history.push(toBack === '/mes-ressources' && lang !== 'FR' ? `/en/my-resources` : toBack);
  };

  const redirectUser = () => {
    dispatch(ApiActions.setTabLayout(0 as any));
    if (user) {
      history.push(mainLink);
    } else {
      history.push('/');
    }
  };

  const LeftElementBar = ({ show }: any) => {
    if (show) {
      return (
        <div className={cls.logoContainer} onClick={redirectUser}>
          <picture className={cls.logo}>
            <source srcSet={`${publicLogoPath}/e-orientaction.png`} />

            <img
              src={`${publicLogoPath}/e-orientaction.png`}
              alt="logo-orientation"
              className={cls.logoImg}
            />
          </picture>
          {/* <img className={cls.logo} src={`${publicLogoPath}/logo-orientaction.png`} alt="logo" /> */}
        </div>
      );
    }

    if (xsDown) {
      return (
        <>
          {toBack ? (
            <div className={cls.buttonBack} onClick={redirect}>
              <IconButton>
                <ArrowBackIcon />
              </IconButton>
            </div>
          ) : (
            <>
              <IconButton onClick={redirect}>
                <ArrowBackIcon />
              </IconButton>
            </>
          )}
        </>
      );
    }

    return (
      <>
        {toBack ? (
          <Link
            to={toBack === '/mes-ressources' && lang !== 'FR' ? `/en/my-resources` : toBack}
            className={cls.buttonBack}
          >
            <Button
              data-cy="button-back"
              className={cls.buttonBack}
              startIcon={<ArrowBackIcon />}
              onClick={redirect}
            >
              Retour
            </Button>
          </Link>
        ) : (
          <>
            {' '}
            <Button
              data-cy="button-back"
              className={cls.buttonBack}
              startIcon={<ArrowBackIcon />}
              onClick={redirect}
            >
              Retour
            </Button>
          </>
        )}
      </>
    );
  };

  const switchRole = async () => {
    try {
      const isSecondRoleActivated = user?.company_user_role?.id === 4;
      const token = getFromLS('userToken') || '';
      const { data } = await userService.switchRole(token, !isSecondRoleActivated);
      localStorage.setItem('userInfos', JSON.stringify(data));
      history.push('/');
    } catch (error) {
      alert.openSimpleAlert();
    }
  };

  return (
    <div className={clsx(cls.layout, cls.gradientBG)}>
      {!isWhite && showToplines && <div className={cls.topLineContainer} />}
      {!isWhite && showBottomLines && <div className={cls.bottomLineContainer} />}
      <div
        className={clsx(
          cls.content,
          isWhite ? cls.bgWhite : cls.gradientBG,
          noPadding ? cls.noPadding : null,
          isTransparent ? cls.transparentBg : null,
          isNew3 ? cls.imageBG3 : null,
        )}
      >
        <AppBar position={typeScroll} color="transparent" elevation={0} className={cls.appBar}>
          <nav className={showLogo ? cls.navbar : cls.navbarWithoutLogo}>
            <LeftElementBar show={showLogo} />

            {user && (
              <div className={cls.profilContainer}>
                <UserPhotos
                  anchorRef={anchorRef}
                  user={user}
                  handleToggle={handleToggle}
                  open={open}
                />

                <ProfileMenu
                  open={open}
                  anchorRef={anchorRef}
                  handleToggle={handleToggle}
                  handleClose={handleClose}
                  goToProfileEdit={goToProfileEdit}
                  logout={logout}
                  user={user}
                  switchRole={switchRole}
                />
              </div>
            )}
          </nav>
        </AppBar>

        <div id="ScrollSmooth">{children}</div>
      </div>
    </div>
  );
};

LayoutResultat.defaultProps = {
  showLogo: true,
};

export default LayoutResultat;
