/* eslint-disable no-sparse-arrays */
/* eslint-disable prefer-template */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import MySelect from './MySelect';
import { useEffect, useMemo, useState } from 'react';
import { some } from 'lodash';
import { apiServiceNative } from '../../utils/fetchNative';
import { useSnackbar } from 'react-simple-snackbar';

// const options = [
//   { value: 1, label: 'Bénéficiaires non-rattachés' },
//   { value: 2, label: 'Prestations Orientaction' },
//   { value: 4, label: 'Espaces entreprises spécifiques' },
// ];

const Option = (props: any) => {
  const { label, isSelected, value } = props;
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          style={value > 20 ? { marginLeft: 20 } : {}}
          checked={isSelected}
          onChange={() => null}
        />{' '}
        <label>{label}</label>
      </components.Option>
    </div>
  );
};

const allOption = {
  label: 'Autorisé à tous',
  value: 0,
};

const ValueContainer = ({ children, ...props }: any) => {
  const currentValues = props.getValue();
  let toBeRendered = children;
  if (currentValues.some((val: any) => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]];
  }

  return <components.ValueContainer {...props}>{toBeRendered}</components.ValueContainer>;
};

const MultiValue = (props: any) => {
  const { data } = props;
  let labelToBeDisplayed = `${data.label}, `;
  if (data.value === allOption.value) {
    labelToBeDisplayed = 'Autorisé à tous';
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

const animatedComponents = makeAnimated();

const PermissionSelectGame = (props: any) => {
  const { options: optionsValue, idGame, permission, handleShow, handlePermission } = props;
  const [openSnackbar, closeSnackbar] = useSnackbar({
    style: {
      backgroundColor: '#000A8C',
    },
  });
  const options1 = [
    { value: 1, label: 'Bénéficiaires non-rattachés' },
    { value: 2, label: 'Prestations Orientaction' },
  ];
  const options2 = [{ value: 4, label: 'Espaces entreprises spécifiques' }];

  const options12 = useMemo(() => {
    if (optionsValue) {
      return optionsValue?.services?.map((opt: any) => {
        return {
          value: parseInt('2' + opt.id, 10),
          label: opt.name,
          src: 'services',
        };
      });
    }

    return [];
  }, [optionsValue, permission]);

  const options22 = useMemo(() => {
    if (optionsValue) {
      return optionsValue?.company?.map((opt: any) => {
        return {
          value: parseInt('4' + opt.id, 10),
          label: opt.name,
          src: 'company',
        };
      });
    }
    return [];
  }, [optionsValue, permission]);

  const [options, setOptions] = useState([...options1, ...options2]);
  const [values, setValues] = useState(null);
  const handleChange = async (selected: any) => {
    const concatOption1 = some(selected, ['value', 2]) ? [...options1, ...options12] : options1;
    const concatOption2 = some(selected, ['value', 4]) ? [...options2, ...options22] : options2;
    setValues(selected);
    setOptions([...concatOption1, ...concatOption2]);
    const reform: any = reformatValue(selected);
    if (permission && permission?.id) {
      reform.id = permission.id;
    }

    const updatedGame = await apiServiceNative.put(
      `/api/update-authorization-game/${idGame}`,
      reform
    );

    if (updatedGame && updatedGame.id) {
      openSnackbar('Choix sauvegardés', 2000);
      handleShow(true);
      handlePermission(updatedGame?.authorizations);
    }
    console.log({ reform, updatedGame });
  };
  const reformatValue = (optionsToReformat: any) => {
    const servicesGame: any = [];
    const companiesGame: any = [];
    const isAttach = some(optionsToReformat, ['value', 1]);
    optionsToReformat.forEach((opt: any) => {
      if (opt.src) {
        if (opt.src === 'services') {
          servicesGame.push(parseInt(opt.value.toString().substring(1), 10));
        }
        if (opt.src === 'company') {
          companiesGame.push(parseInt(opt.value.toString().substring(1), 10));
        }
      }
    });
    return {
      isAttach,
      companiesGame: companiesGame.toString(),
      servicesGame: servicesGame.toString(),
    };
  };
  const reformatDefaultValue = (defaultValues: any) => {
    const { companiesGame, servicesGame, isAttach } = defaultValues;
    const valuesCompany = [];
    const valuesServices = [];
    const valueIsAttach = isAttach ? [] : [{ value: 1, label: 'Bénéficiaires non-rattachés' }];
    const allValues = [];
    if (companiesGame && companiesGame.length > 0) {
      valuesCompany.push({ value: 4, label: 'Espaces entreprises spécifiques' });
      companiesGame.forEach((comp: any) => {
        valuesCompany.push({
          value: parseInt('4' + comp.id, 10),
          label: comp.name,
          src: 'company',
        });
      });
    }
    if (servicesGame && servicesGame.length > 0) {
      valuesServices.push({ value: 2, label: 'Prestations Orientaction' });
      servicesGame.forEach((srv: any) => {
        valuesServices.push({
          value: parseInt('2' + srv.id, 10),
          label: srv.name,
          src: 'services',
        });
      });
    }
    if (
      valueIsAttach &&
      servicesGame.length === options12.length &&
      companiesGame.length === options22.length
    ) {
      allValues.push(allOption);
    }

    const concatOption1 = some(valuesServices, ['value', 2]) ? [...options1, ...options12] : options1;
    const concatOption2 = some(valuesCompany, ['value', 4])
      ? [...options2, ...options22]
      : options2;
    setOptions([...concatOption1, ...concatOption2]);

    console.log({ values: [...allValues, ...valueIsAttach, ...valuesServices, ...valuesCompany] });
    return [...allValues, ...valueIsAttach, ...valuesServices, ...valuesCompany];
  };

  useEffect(() => {
    setValues(reformatDefaultValue(permission) as any);
  }, [permission, optionsValue]);

  return (
    <MySelect
      options={options}
      allOption={allOption}
      isMulti={true}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{
        Option,
        MultiValue,
        ValueContainer,
        animatedComponents,
      }}
      onChange={handleChange}
      allowSelectAll={true}
      value={values}
      defaultValue={true}
      hasError={false}
      noBorder={true}
      label="Autorisations"
      options1={[...options1, ...options12]}
      options2={[...options2, ...options22]}
    />
  );
};

export default PermissionSelectGame;
