/* eslint-disable react/button-has-type */
/* eslint-disable prettier/prettier */
import { Grid, Typography } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { gameBookTemplateActions } from '@orientaction/api-actions';
import { gameBookValues } from '@orientaction/reducers';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditorImg from '../../EditorImg';
import '../choice.css';
import style from '../style';

const placeholder = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <span
        style={{
          fontSize: 26,
          margin: 8,
          border: '1px solid #E4E4F2',
          borderRadius: '50%',
          width: 36,
          height: 36,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        +
      </span>
      <span style={{ fontSize: 16 }}>Importer une image</span>
    </div>
  );
};
const Model6 = (props: any) => {
  const classes = style();
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState('');
  const [position, setPosition] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);
  const allPages = sessionStorage.getItem('gamePages')
    ? JSON.parse(sessionStorage.getItem('gamePages') || '')
    : [];
  const optionsValue = [
    {
      value: 1,
      label: 'Normal',
    },
    {
      value: 2,
      label: 'Citation',
    },
    {
      value: 3,
      label: 'Encyclopédie',
    },
  ];

  const dispatch = useDispatch();
  const { onChoiceClick, choices, choiceTitle, typeChoice, textEndGame, displayCondition } = props;
  const handleFileUrlChange = (urlC: string) => {
    setUrl(urlC);
  };
  const handleFileChange = (fileC: any) => {
    handleFieldChange('files', fileC);
    // setFile(fileC);
  };
  const handleFieldChange = (field: string, value: any) => {
    dispatch(gameBookTemplateActions.updateField(field, value));
  };
  const handleDeleteFile = () => {
    setFile(null);
    setUrl('');
  };

  const handleOpen = (event: any) => {
    onChoiceClick(true);
    event.preventDefault();
  };
  const valuesBooksTemplate = useSelector(gameBookValues);
  const isChoice = choices.length > 0 || typeChoice === 'end_game';

  function getTitleByIndex(id: number) {
    const page = allPages.find((res: any) => res?.id === id);

    if (id === -1) {
      return 'Couverture du jeu'
    }
    if (page) {
      return page.title || 'Page sans titre';
    }

    return 'ID non trouvé';
  }

  return (
    <div className={classes.container} key={valuesBooksTemplate.id}>
      <Grid className={classes.blockPadding} container={true} style={{ height: '100%' }}>
        <Grid
          item={true}
          sm={12}
          className={classes.blockContentParent}
        >
          <div
            className={classes.blockContent}
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Typography variant="h5" className={classes.title}>
              Illustration
            </Typography>
            <div style={{ marginTop: 15, height: '100%', position: 'relative' }}>
              <EditorImg
                key={valuesBooksTemplate.id}
                width={508}
                height={622}
                handleFile={handleFileChange}
                defaultUrl={
                  valuesBooksTemplate?.illustration?.url || valuesBooksTemplate?.tempImage || null
                }
              />
            </div>
          </div>
        </Grid>
        <Grid item={true} sm={12} className={classes.blockContentParent}>
          <div
            className={classes.blockContent}
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
            }}
          >
            {typeChoice === 'end_game' ? (
              <Typography variant="h6" className={classes.titleEndGame}>
                CHOIX FIN DU JEU
              </Typography>
            ) : (
              <Typography variant="h5" className={classes.title}>
                Choix
              </Typography>
            )}
            <div
              onClick={handleOpen}
              className={
                displayCondition === 'horizontal'
                  ? 'wrapperchoices'
                  : 'wrapperchoices vertical--center'
              }
              style={{ marginTop: 10, height: '100%' }}
            >
              <>
                {typeChoice === 'end_game' ? (
                  <h3 className="choicetitleEnd">
                    {choiceTitle || 'Ceci est la fin du jeu. Merci.'}
                  </h3>
                ) : (
                  <h3 className="choicetitle">{choiceTitle || ''}</h3>
                )}
                {typeChoice === 'end_game' && (
                  <>
                    {' '}
                    <div className="wrapper-btn">
                      <button className="custom-button">
                        <span>{textEndGame || 'Ajouter un texte de fin du jeu'}</span>
                        <ArrowForwardIcon className="icon" />
                      </button>
                    </div>{' '}
                  </>
                )}
                {isChoice ? (
                  typeChoice !== 'end_game' &&
                  (displayCondition === 'horizontal' ? (
                    choices.slice(0, 9).map((choice: any, index: any) => (
                      <div key={choice.id} className="choice-container">
                        <div className="choice-title">
                          {typeChoice === 'pursue' ? 'Poursuivre' : `Choix ${index + 1}`}
                        </div>
                        <div
                          className="choice-content"
                          dangerouslySetInnerHTML={{
                            __html: choice?.text,
                          }}
                        />
                        <div className="choice-button">
                          <button>
                            <span className="arrow">{}</span>
                            <span className="intro">
                              {allPages?.length > 0 &&
                              choice.destination &&
                              typeChoice !== 'redirection'
                                ? getTitleByIndex(choice.destination)
                                : choice?.destinationUrl}
                            </span>
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="carrer-wrapper" style={{ marginTop: 10 }}>
                      {choices.slice(0, 9).map((choice: any) => (
                        <div key={choice.id} className="choice-content-carrer">
                          {choice?.name}
                        </div>
                      ))}
                    </div>
                  ))
                ) : (
                  <div className={classes.containerChoice}>
                    <div className={classes.choice}>
                      <span
                        style={{
                          fontSize: 26,
                          margin: 8,
                          border: '1px solid #E4E4F2',
                          borderRadius: '50%',
                          width: 36,
                          height: 36,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        +
                      </span>
                      <span style={{ fontSize: 16, cursor: 'pointer' }}>Ajouter des choix</span>
                    </div>
                  </div>
                )}

                {isChoice && (
                  <div className="manage-choices">
                    <button className="manage-choices-button">
                      Gérer les choix
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M7.16667 15.5V10.5H8.83333V12.1667H15.5V13.8333H8.83333V15.5H7.16667ZM0.5 13.8333V12.1667H5.5V13.8333H0.5ZM3.83333 10.5V8.83333H0.5V7.16667H3.83333V5.5H5.5V10.5H3.83333ZM7.16667 8.83333V7.16667H15.5V8.83333H7.16667ZM10.5 5.5V0.5H12.1667V2.16667H15.5V3.83333H12.1667V5.5H10.5ZM0.5 3.83333V2.16667H8.83333V3.83333H0.5Z"
                            fill="#000A8C"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                )}
              </>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Model6;
