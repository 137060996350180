/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { CardTestSimple } from '@orientaction/components';
import { FC } from 'react';
import { Grid } from '@material-ui/core';
import useStyle from './style';
import { useMe } from '@orientaction/hooks';

interface ISecondEtage {
  listSecondEtape: any[];
  me?: any;
  loading?: boolean;
  freeTests?: any[];
}

const SecondEtage: FC<ISecondEtage> = ({ listSecondEtape, me, loading, freeTests }) => {
  const classe = useStyle();

  const hasPriority = !!listSecondEtape.filter((item: any) => item.priority === 1).length;

  const verifyList = () => {
    if (hasPriority) {
      if (listSecondEtape.length > 8) {
        return listSecondEtape.slice(9, listSecondEtape.length);
      }

      return [];
    }

    return listSecondEtape;
  };

  return (
    <>
      <Grid container={true} spacing={1} className={classe.marginTop}>
        {verifyList().map((item: any) => (
          <Grid item={true} xl={4} lg={4} md={4} sm={6} xs={12}>
            <CardTestSimple
              key={item.id}
              id={item.id}
              status="actif"
              title={item.title}
              description={item.description}
              isDone={true}
              image={item.image}
              time={item.time}
              isFavorite={false}
              showPastille={item.showPastille}
              me={me}
              loading={loading}
              freeTests={freeTests}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default SecondEtage;
