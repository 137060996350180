import clsx from 'clsx';
import { FC } from 'react';
import Select from 'react-select';
import style from './style';
import { some } from 'lodash';

interface IMyselect {
  allowSelectAll: any;
  allOption: any;
  options: any;
  onChange: any;
  isMulti: boolean;
  closeMenuOnSelect: boolean;
  hideSelectedOptions: boolean;
  components: any;
  value: any;
  defaultValue: boolean;
  hasError: boolean;
  label?: any;
  noBorder?: boolean;
  options1?: any;
  options2?: any;
}

const MySelect: FC<IMyselect> = ({
  allowSelectAll,
  allOption,
  options,
  onChange,
  isMulti,
  closeMenuOnSelect,
  hideSelectedOptions,
  components,
  value,
  defaultValue,
  hasError = false,
  label,
  noBorder = false,
  options1,
  options2,
}) => {
  const cls = style();

  if (allowSelectAll) {
    return (
      <Select
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={hideSelectedOptions}
        components={components}
        value={value}
        menuPlacement="auto"
        menuShouldScrollIntoView={false}
        className={clsx({
          [cls.select]: true,
          [cls.inputError]: hasError,
          [cls.noBorder]: noBorder,
        })}
        defaultValue={true}
        options={[allOption, ...options]}
        placeholder={label || 'Sélectionner un espace'}
        onChange={(selected: any, event: any) => {
          console.log({selectedInside: selected, options, value})
          if (selected !== null && selected.length > 0) {
            if (selected[selected.length - 1].value === allOption.value) {
              if (options1 && options2) {
                return onChange([allOption, ...options1, ...options2]);
              }
              return onChange([allOption, ...options]);
            }

            let result = [];

            if (selected.length === options.length) {
              if (selected.includes(allOption)) {
                result = selected.filter((option: any) => option.value !== allOption.value);
              } else if (event.action === 'select-option') {
                result = [allOption, ...options];
              }
              return onChange(result);
            }
            if (options1 && options2) {
              if (!some(selected, ['value', 2]) && event?.option?.value === 2) {
                return onChange(selected.filter((option: any) => !(option?.value > 20 && option?.value < 26)));
              }
              if (!some(selected, ['value', 4]) && event?.option?.value === 4) {
                return onChange(selected.filter((option: any) => !(option?.value > 40)));
              }
            }
          }

          return onChange(selected);
        }}
      />
    );
  }

  return (
    <Select
      isMulti={isMulti}
      closeMenuOnSelect={closeMenuOnSelect}
      hideSelectedOptions={hideSelectedOptions}
      menuPlacement="auto"
      menuShouldScrollIntoView={false}
      className={clsx({
        [cls.select]: true,
        [cls.inputError]: hasError,
      })}
      components={components}
      value={value}
      defaultValue={defaultValue}
    />
  );
};

export default MySelect;
