/* eslint-disable no-lonely-if */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-use-before-define */
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import { ApiActions } from '@orientaction/api-actions';
import { useLanguage } from '@orientaction/hooks';
import { apiState } from '@orientaction/reducers';
import { GB_PREFIX, getFromLS } from '@orientaction/utils';
// eslint-disable-next-line no-use-before-define
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ModalEditGame from '../../pages/AdminGame/EditPage/modal-edit-game';
import AntTab from './AntTab';
import AntTabAdmin from './AntTabAdmin';

const AntTabs = withStyles({
  root: {
    width: 'auto',
    color: '#000A8C !important',
    '& > div': {
      paddingTop: 11,
      paddingBottom: 10,
    },
  },
  indicator: {
    background:
      'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
  },
})(Tabs);

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  demo1: {
    backgroundColor: 'white',
    width: 'auto',
    borderRadius: '50px',
  },
  centerLoading: {
    marginTop: 14,
    marginLeft: 27,
  },
}));

interface IProps {
  isAdmin: boolean;
  isRoot?: boolean;
  service: any;
}

const TabLayout: FC<IProps> = ({ isAdmin, isRoot = false, service }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { tabLayout } = useSelector(apiState);
  const { language } = useLanguage();

  const history = useHistory();
  const [idRoute, setIdRouting] = useState('');
  const [nexRoute, setNextRoute] = useState(0);
  const { pathname } = useLocation();
  const [openExit, setOpenExit] = useState(false);
  const currentUser = JSON.parse(getFromLS('userInfos') || '{}');

  useEffect(() => {
    setIdRouting(pathname);
    if (isAdmin) {
      if (!currentUser.isRGPD) {
        if (pathname.includes('/admin/test')) {
          dispatch(ApiActions.setTabLayout(2 as any));
        } else if (pathname.includes('/admin/business-spaces')) {
          dispatch(ApiActions.setTabLayout(3 as any));
        } else if (pathname.includes('/admin/game')) {
          dispatch(ApiActions.setTabLayout(1 as any));
        } else if (pathname.includes('/admin/ebook')) {
          dispatch(ApiActions.setTabLayout(1 as any));
        } else {
          dispatch(ApiActions.setTabLayout(0 as any));
        }
      } else {
        if (pathname.includes('/admin/business-spaces')) {
          dispatch(ApiActions.setTabLayout(1 as any));
        } else {
          dispatch(ApiActions.setTabLayout(0 as any));
        }
      }
    } else {
      switch (pathname) {
        case '/main':
          dispatch(ApiActions.setTabLayout(0 as any));
          break;
        case '/user/tests':
          dispatch(ApiActions.setTabLayout(1 as any));
          break;
        case '/mes-resultats':
          dispatch(ApiActions.setTabLayout(2 as any));
          break;
        case '/mes-ressources':
          dispatch(ApiActions.setTabLayout(3 as any));
          break;
        case `/ma-boussole/${new Date().getFullYear()}`:
          dispatch(ApiActions.setTabLayout(4 as any));
          break;
        default:
          break;
      }
    }
  }, [pathname]);

  const handleCancel = () => {
    setOpenExit(false);
  };

  const handleClose = () => {
    setOpenExit(false);
  };

  const handleConfirm = () => {
    dispatch(ApiActions.setTabLayout(nexRoute as any));
    if (isAdmin) {
      if (!currentUser.isRGPD) {
        switch (nexRoute) {
          case 0:
            history.push('/admin/users-resultats');

            break;
          case 1:
            history.push('/admin/game/list');

            break;
          case 2:
            history.push('/admin/test');

            break;
          case 3:
            history.push('/admin/business-spaces');

            break;
          default:
            break;
        }
      } else {
        switch (nexRoute) {
          case 0:
            history.push('/admin/users-resultats');

            break;
          case 1:
            history.push('/admin/business-spaces');

            break;
          default:
            break;
        }
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    // let newValue = values;

    setNextRoute(newValue);
    if (
      (isAdmin && idRoute && idRoute?.includes('game/edit-page')) ||
      (isAdmin && idRoute && idRoute?.includes('ebook/edit'))
    ) {
      setIdRouting('game/edit-page');

      setOpenExit(true);
      return;
    }

    dispatch(ApiActions.setTabLayout(newValue));
    if (isAdmin) {
      if (!currentUser.isRGPD) {
        switch (newValue) {
          case 0:
            setTimeout(() => {
              history.push('/admin/users-resultats');
            }, 1000);

            break;
          case 1:
            setTimeout(() => {
              history.push('/admin/game/list');
            }, 1000);

            break;
          case 2:
            setTimeout(() => {
              history.push('/admin/test');
            }, 1000);

            break;
          case 3:
            setTimeout(() => {
              history.push('/admin/business-spaces');
            }, 1000);

            break;
          default:
            break;
        }
      } else {
        switch (newValue) {
          case 0:
            setTimeout(() => {
              history.push('/admin/users-resultats');
            }, 1000);

            break;
          case 1:
            setTimeout(() => {
              history.push('/admin/business-spaces');
            }, 1000);

            break;
          default:
            break;
        }
      }
    } else {
      switch (newValue) {
        case 0:
          setTimeout(() => {
            history.push('/main');
          }, 1000);
          break;
        case 1:
          setTimeout(() => {
            history.push('/user/tests');
          }, 1000);
          break;
        case 2:
          setTimeout(() => {
            history.push(`${language === GB_PREFIX ? `/en/my-results` : `/mes-resultats`}`);
          }, 1000);
          break;
        case 3:
          setTimeout(() => {
            history.push(`${language === GB_PREFIX ? `/en/my-resources` : `/mes-ressources`}`);
          }, 1000);
          break;
        case 4:
          setTimeout(() => {
            history.push(
              `${
                language === GB_PREFIX
                  ? `/en/my-compass/${new Date().getFullYear()}`
                  : `/ma-boussole/${new Date().getFullYear()}`
              }`
            );
          }, 1000);
          break;
        default:
          break;
      }
    }
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        {isAdmin ? (
          <>
            {' '}
            <ModalEditGame
              open={openExit}
              title=""
              handleClose={handleClose}
              message={`Êtes-vous sûr de vouloir quitter l'édition ${
                !sessionStorage.getItem('book') ? 'du jeu' : `du book`
              } ?`}
              onCancel={handleCancel}
              onConfirm={handleConfirm}
            />
            <AntTabs
              variant="fullWidth"
              value={tabLayout}
              onChange={handleChange}
              aria-label="ant example">
              <AntTabAdmin label="Utilisateurs et résultats" {...a11yProps(0)} />
              {isRoot && !currentUser.isRGPD && (
                <AntTabAdmin label="Admin. des ressources" {...a11yProps(1)} />
              )}
              {isRoot && !currentUser.isRGPD && (
                <AntTabAdmin label="Administration des tests" {...a11yProps(2)} />
              )}
              {isRoot && <AntTabAdmin label="Espaces entreprises" {...a11yProps(3)} />}
            </AntTabs>
          </>
        ) : (
          <AntTabs
            variant="fullWidth"
            value={tabLayout}
            onChange={handleChange}
            aria-label="ant example">
            <AntTab label="Accueil" {...a11yProps(0)} />
            <AntTab label="Faire un test" {...a11yProps(1)} />
            <AntTab label="Mes résultats" {...a11yProps(2)} />
            <AntTab label="Mes ressources" {...a11yProps(3)} />
            {service?.id !== 2 && <AntTab label="Ma boussole" {...a11yProps(3)} />}
          </AntTabs>
        )}
      </div>
    </div>
  );
};

export default TabLayout;
