/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, SimpleModal, TitleNbr } from '@orientaction/components';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { imgBookIntro } from '../../../../utils/constants';
import { useLanguage } from '@orientaction/hooks';
import { Card } from '@material-ui/core';
import { blockRedirection } from '@orientaction/utils';
import { useSelector } from 'react-redux';
import { appLanguage } from '@orientaction/reducers';

const Page31 = (props: any) => {
  const classes = useStyles();
  const { language } = useLanguage();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const lang: string = useSelector(appLanguage);

  const image = useMemo(() => {
    return language === 'GB' ? 'en_intro.png' : 'intro.png';
  }, [language]);
  const imageQRCode = useMemo(() => {
    return language === 'GB' ? 'en_rabit_test_qr_code.jpeg' : 'rabit_test_qr_code.jpg';
  }, [language]);

  const imageBtn = useMemo(() => {
    return language === 'GB' ? 'BOUTON_WEB1_EN.png' : 'BOUTON_WEB1.png';
  }, [language]);

  return (
    <Page id={31}>
      <div>
        <div>
          <div style={{ textAlign: 'center' }}>
            <Typography variant="h1" component="h1" gutterBottom={true} className="opacity07">
              LE TEST DU LAPIN
            </Typography>
            <br />
            <Typography
              className={classes.testimony}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                fontWeight: 'bold',
                fontStyle: 'italic',
                fontSize: 20,
              }}>
              <strong>Pour mieux comprendre son rapport au changement (en s’amusant)</strong>
            </Typography>
          </div>
        </div>
      </div>
      <div
        className={clsx(classes.trait, classes.positionCenter)}
        style={{ marginTop: '50px', marginBottom: '50px' }}
      />
      <img
        src={`${imgBookIntro}/${image}`}
        alt="patissier"
        style={{
          width: '40%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
      <Grid container={true} spacing={1} style={{ marginTop: '50px' }} justifyContent="center">
        <Grid item={true} xs={12}>
          <Typography
            className={classes.testimony}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              fontWeight: 'bold',
            }}>
            <strong>
              Que ferez-vous quand il n’y aura plus de carottes ? Comment réagissez-vous au
              changement ? Quel type de lapin êtes-vous ?
            </strong>
          </Typography>
        </Grid>
      </Grid>
      <div>
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Qu’on ne s’y trompe pas : l’ouvrage que vous tenez entre vos mains est un ouvrage
              sérieux. Sérieux et ludique : un livre d’un genre nouveau donc, pour penser notre
              époque avec un regard neuf, un livre qui propose plus qu’il n’impose, qui suggère plus
              qu’il n’assène.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              On y croise des lapins animés et colorés, on y joue en devenant le héros d’une
              histoire qui nous met au défi… mais peu à peu le ludique s’estompe pour laisser place
              à la réflexion.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '20px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Car l’enjeu est de taille : se préparer à faire face aux bouleversements tous azimuts
              déjà perceptibles aujourd’hui autour de nous, qu’ils soient climatiques, économiques
              ou sociétaux et qui demain balaieront tous nos repères si nous n’y prenons garde.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Mais l’auteur n’a pas le goût du drame et refuse d’abdiquer devant les catastrophes
              annoncées. Son antidote : la connaissance de soi.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Son maître-mot : le changement. Scandé comme un leitmotiv tout au long du livre, il
              fait du changement une conviction : les hommes et les femmes ont le choix, ils
              disposent de ressources intérieures infinies pour affronter bien des périls. On lui
              parle de risques et d’incertitudes ?
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Il répond opportunités et dépassement de soi et nous invite à transformer nos peurs en
              énergie positive.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Emeric Lebreton a choisi d’employer un ton volontairement léger pour traiter des
              questions, qui, de livre en livre, le préoccupent et le poussent à poursuivre à chaque
              fois le même but : contribuer au bonheur de chacun.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              C’est cette méthode que vous avez choisie pour réussir votre évolution
              professionnelle. Grâce à elle, vous adoptez les bonnes attitudes, définissez les
              bonnes stratégies, faites les bons choix pour réussir votre évolution professionnelle.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Pour passer le test :
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className={classes.containerImg}>
        <img src={`${imgBookIntro}/${imageBtn}`} alt="couverture intro" className={classes.img} />
        <span
          
          onClick={() =>
            blockRedirection({
              language: lang,
              onOpen: () => setOpenModal(true),
              functionToPass: () => {
                window.open(
                  'https://e-orientaction.com/game/7/Test-du-lapin',
                  '_blank'
                );
              },
            })
          }
          className={classes.btnTest}>
          Clique
        </span>
        <SimpleModal
          open={openModal}
          handleClose={() => setOpenModal(false)}
          title="Information"
          message="Not yet available in English – work in progress."
        />
      </div>
      {/* <div className={classes.qrCodeContainer}>
        <div className={classes.qrCodeLeftContentContainer}>
          <img
            className={classes.qrCodeImage}
            src={`${imgBookIntro}/${imageQRCode}`}
            alt="Test du lapin qr code"
          />
          <div className={classes.textBlockContainer}>
            <div>
              <p className={classes.textBlock}>
                <b className={classes.blueText}>Flashez ce QR code</b> avec l’appareil photo de
                votre smartphone pour télécharger gratuitement le livre en format electronique.
              </p>
            </div>
            <div className={classes.secondParagraphContainer}>
              <p className={classes.textBlock}>
                Disponible également en format papier à la Fnac, sur Amazon et dans toutes les
                bonnes librairies au prix de 5 euros TTC.
              </p>
            </div>
          </div>
        </div>
        <img
          className={classes.phoneImage}
          src={`${imgBookIntro}/handle_smartphone.png`}
          alt="Main qui tient une smartphone"
        />
      </div> */}
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
    testimony: {
      fontSize: '17px',
    },
    bgGreyMargin: {
      marginTop: '50px',
    },
    qrCodeContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 75,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    qrCodeLeftContentContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    qrCodeImage: {
      width: '9vw',
      objectFit: 'contain',
      border: '3px solid #E63C3C',
      [theme.breakpoints.down('sm')]: {
        width: '20vw',
      },
      [theme.breakpoints.down('xs')]: {
        width: '40vw',
      },
    },
    phoneImage: {
      width: '10vw',
      objectFit: 'contain',
      [theme.breakpoints.down('sm')]: {
        width: '20vw',
        marginTop: 10,
      },
      [theme.breakpoints.down('xs')]: {
        width: '40vw',
        marginTop: 10,
      },
    },
    textBlock: {
      fontSize: 14,
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      fontWeight: 400,
      lineHeight: 1.5,
      color: '#5A5A5A',
    },
    secondParagraphContainer: {
      marginTop: 7,
    },

    blueText: {
      color: '#2B56A5',
      fontFamily: 'ITC Avant Garde Gothic Std Bold',
    },
    textBlockContainer: {
      marginLeft: 20,
      [theme.breakpoints.down('sm')]: {
        marginTop: 10,
        marginLeft: 0,
      },
    },
    btnTest: {
      position: 'absolute',
      zIndex: 2,
      border: '2px solid black',
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      opacity: 0,
      top: '0%',
      left: '0%',
    },
    containerImg: {
      position: 'relative',
      display: 'block',
      width: '35%',
      height: 'auto',
      margin: 'auto',
      marginTop: '40px',
    },
  })
);
export default Page31;
