/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-return */
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';

import { gameVariablesTemplateActions } from '@orientaction/api-actions';
import { gameBookValues, gameVariables, gameVariableValues } from '@orientaction/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Modal, ModelVariable1, ModelVariable2, ModelVariable3, ModelVariable4, ModelVariable5, ModelVariable6, ModelVariable7 } from '@orientaction/components';
import style from '../style';
import { cloneDeep } from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as Border } from '../../../Asset/svg/border.svg';

const EditVariable = (props: any) => {
  const classes = style();
  const dispatch = useDispatch();
  const allVariables = useSelector(gameVariables);
  const valuesVariables = useSelector(gameVariableValues);
  const [isEditTitle, setIsEditTitle] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(valuesVariables.title);
  const refInput = useRef(null);
  const [openModalCancel, setOpenModalCancel] = useState<boolean>(false);
  const { edit }: any = useParams();

  useEffect(() => {
    const refI: any = refInput?.current;

    if (isEditTitle && refI) {
      refI.click();
    }
  }, [isEditTitle]);

  console.log({ valuesVariables });

  const { id }: any = useParams();
  const history = useHistory();

  const renderModelCondition = (idModel: any) => {
    switch (idModel) {
      case 1:
        return <ModelVariable1 indexVariable={id} />;
      case 2:
        return <ModelVariable2 indexVariable={id} />;
      case 3:
        return <ModelVariable3 indexVariable={id} />;
      case 4:
        return <ModelVariable4 indexVariable={id} />;
      case 5:
        return <ModelVariable5 indexVariable={id} />;
      case 6:
        return <ModelVariable6 indexVariable={id} />;
      case 7:
        return <ModelVariable7 indexVariable={id} />;
      default:
        return;
    }
  };
  const handleSubmit = () => {
    const newVariables = cloneDeep(allVariables).map((el: any, index: number) => {
      if (index === parseInt(id, 10)) {
        return {
          ...el,
          ...valuesVariables,
          title,
        };
      }
      return el;
    });
    dispatch(gameVariablesTemplateActions.setAllVariables(newVariables));
    dispatch(gameVariablesTemplateActions.resetValuesModel());
    confirmCancel();
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      // handleSave();
      setIsEditTitle(false);
    }
  };
  const confirmCancel = () => {
    if (!edit) {
      history.push('/admin/game/create-variables');
    } else {
      history.push('/admin/game/create-variables/edit');
    }
  };

  return (
    <Layout isaddBackground={true} showOverFlow={true} key={id}>
      <>
        <Grid container={true}>
          <Grid item={true} sm={12} className={classes.section2}>
            <div style={{ padding: '10px 30px', width: '100%' }}>
              <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {isEditTitle ? (
                    <TextField
                      ref={refInput}
                      variant="outlined"
                      className={classes.formInput}
                      name="test-name"
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                      placeholder="Titre de la page"
                      onBlur={() => setIsEditTitle(!isEditTitle)}
                      onKeyDown={handleKeyDown}
                      autoFocus={true}
                    />
                  ) : (
                    <>
                      <Typography variant="h4" className={classes.title2}>
                        {title || 'Page de restitution sans titre'}
                      </Typography>
                    </>
                  )}
                  <IconButton
                    aria-label="delete"
                    style={{ marginLeft: 15 }}
                    onClick={() => setIsEditTitle(!isEditTitle)}>
                    <Border className={classes.icon} />
                  </IconButton>
                </div>

                <div>
                  <Button
                    variant="outlined"
                    className={classes.button}
                    onClick={() => setOpenModalCancel(true)}>
                    Annuler
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    id="draft-submit"
                    onClick={handleSubmit}
                    className={classes.btnPrimarySticky}>
                    Valider
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container={true} style={{ background: '#F1F1F1' }}>
          <Grid item={true} sm={12} className={classes.section4}>
            {renderModelCondition(valuesVariables?.template_game)}
          </Grid>
        </Grid>
        <Modal
          data-cy="cancel-test-create-modal"
          open={openModalCancel}
          onCancel={() => setOpenModalCancel(false)}
          onConfirm={() => history.goBack()}
          textCancel="Non"
          title=""
          textValidate="Oui">
          <h3 style={{ color: '#000A8C' }}>Êtes-vous sûr de vouloir annuler les modifications ?</h3>
        </Modal>
      </>
    </Layout>
  );
};

export default EditVariable;
