/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { MarginContainer, OaPopin, SelectHieachy } from '@orientaction/commons';
import { getFromLS } from '@orientaction/utils';
import moment from 'moment';
import { FC, useContext, useEffect, useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import PhoneInput from 'react-phone-input-2';
import { useSnackbar } from 'react-simple-snackbar';
import EbookSection from '../Option/EbookSection';
import ServiceSelection from '../Option/ServiceSelection';
import RandomPasswordSection from '../RandomPasswordSection';
import { FORM_MODE, generatePasswordOptionalText } from '../constant';
import style from './style';
import { apiServiceNative } from '../../../../utils/fetchNative';
import { UserFormContext } from '../Context/UserFormContext';
import { cloneDeep, filter, find, flatten, sortBy } from 'lodash';
import MajorTestSelection from '../BeneficiaryForm/MajorTestSelection';
import { validate } from 'email-validator';
import { useAdminUser, useAlert } from '@orientaction/hooks';
import { ApiActions } from '@orientaction/api-actions';
import { useDispatch } from 'react-redux';

interface IProps {
  mode: string;
  companyUserRoleId: number;
  alias: any;
  closeDrawer: () => void;
}

type UserToSendType = {
  firstname: string;
  lastname: string;
  dayOfBirth: Date | string;
  company_user_role: number;
  streetNumber: number | string;
  streetName: string;
  postal_code: string;
  town: string;
  phone: string;
  language: string;
  diploma: string;
  upper_hierarchy: number;
  company: number | string;
  email: string;
  rattachement: boolean;
  tests: Array<any>;
  gender: string;
  tests_to_do: Array<any>;
  service: number;
};

const BeneficiaryFormCarte: FC<IProps> = ({ mode, companyUserRoleId, alias, closeDrawer }) => {
  const classes = style();
  const userConnected = JSON.parse(getFromLS('userInfos') || '{}');
  const [openPopin, setOpenPopin] = useState<boolean>(false);

  const [userToCreate, setUserToCreate] = useState<UserToSendType>({
    firstname: '',
    lastname: '',
    dayOfBirth: '',
    company_user_role: 5,
    streetNumber: '',
    streetName: '',
    postal_code: '',
    town: '',
    phone: '',
    language: '',
    diploma: '',
    upper_hierarchy: userConnected.id,
    company: 1,
    email: '',
    rattachement: true,
    tests: [],
    gender: '',
    tests_to_do: [],
    service: 5,
  });

  const [errorField, setErrorField] = useState({
    firstname: false,
    lastname: false,
    dayOfBirth: false,
    phone: false,
    gender: false,
    email: false,
    diploma: false,
  });

  const alert = useAlert();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (Object.keys(errors).length > 0) {
  //     openSnackbar('merci de remplir les champs requis');
  //   }
  // }, [errors]);

  const [categorieTests, setCategorieTests] = useState<any>();
  const [allTestsAllowed, setAllTestsAllowed] = useState<any>();
  const [majorTestsLoading, setMajorTestsLoading] = useState<boolean>(false);
  const {
    updateArrayAfterUpdateCreate,
  } = useAdminUser();

  useEffect(() => {
    const fetchGameDetails = async () => {
      try {
        const response = sessionStorage.getItem('categories-test')
          ? JSON.parse(sessionStorage.getItem('categories-test') || '')
          : [];
        const dataUserUpper = await apiServiceNative.get(
          `/api/users/${userConnected.id}?populate[category_tests][fields][0]=id`
        );
        if (response) {
          const categoriesToFilter = filter(response.data, (el: any) =>
            dataUserUpper?.category_tests?.some((res: any) => res.id === el.id)
          );
          setCategorieTests(categoriesToFilter);
          const allTests = flatten(categoriesToFilter.map(elem => elem.attributes.tests.data));
          setAllTestsAllowed(allTests);
        } else {
          console.error('Invalid response structure:', response);
        }
      } catch (error) {
        console.error('Error fetching game details:', error);
      }
    };
    const fetchMajorTest = async () => {
      setMajorTestsLoading(true);
      try {
        const dataMajorTests = await apiServiceNative.get(`/api/major-tests-templates`);
        if (dataMajorTests) {
          const testsMajor = dataMajorTests.data;
          setUserToCreate({ ...userToCreate, tests: testsMajor });
        } else {
          console.error('Invalid response structure:', dataMajorTests);
        }
        setMajorTestsLoading(false);
      } catch (error) {
        console.error('Error fetching game details:', error);
        setMajorTestsLoading(false);
      }
    };
    fetchGameDetails();
    fetchMajorTest();
  }, []);

  const handleChange = (e: any) => {
    const { name, value, checked } = e.target;
    const testId = +name.split('_')[1];
    const updatedTests = userToCreate.tests.map((test: any) => {
      if (test.id === testId) {
        return { ...test, isChecked: checked };
      }
      return test;
    });
    setUserToCreate({ ...userToCreate, tests: updatedTests });
  };

  const handleChangeField = (field: string, value: any) => {
    console.log({ field, value });
    setUserToCreate({ ...userToCreate, [field]: value });
  };

  const handleChangeTest = (e: any) => {
    const { value, checked } = e.target;
    const testToFind = find(allTestsAllowed, (test: any) => test.id === parseInt(value, 10));
    const testsWithoutAttributes = {
      id: testToFind.id,
      ...testToFind.attributes,
    };

    const userTests = cloneDeep(userToCreate.tests_to_do) || [];
    console.log({ userTests, testsWithoutAttributes });
    const isTestExistIntoUser = userTests?.some((elem: any) => elem?.id === parseInt(value, 10));

    if (!isTestExistIntoUser && checked) {
      setUserToCreate({
        ...userToCreate,
        tests_to_do: [...userTests, testsWithoutAttributes],
      });
    } else {
      setUserToCreate({
        ...userToCreate,
        tests_to_do: filter(userTests, (elem: any) => elem.id !== testsWithoutAttributes?.id),
      });
    }
  };

  const handleSubmit = async () => {
    try {
      console.log({ userToCreate });
      if (!userToCreate.firstname) {
        setErrorField({ ...errorField, firstname: true });
        return;
      }
      setErrorField({ ...errorField, firstname: false });

      if (!userToCreate.lastname) {
        setErrorField({ ...errorField, lastname: true });
        return;
      }
      setErrorField({ ...errorField, lastname: false });

      if (!userToCreate.dayOfBirth) {
        setErrorField({ ...errorField, dayOfBirth: true });
        return;
      }
      setErrorField({ ...errorField, dayOfBirth: false });

      if (!userToCreate.gender) {
        setErrorField({ ...errorField, gender: true });
        return;
      }
      setErrorField({ ...errorField, gender: false });

      if (!userToCreate.phone || userToCreate.phone?.length <= 2) {
        setErrorField({ ...errorField, phone: true });
        return;
      }
      setErrorField({ ...errorField, phone: false });

      if (!userToCreate.email || !validate(userToCreate.email)) {
        setErrorField({ ...errorField, email: true });
        return;
      }
      setErrorField({ ...errorField, email: false });

      if (userToCreate.tests_to_do.length === 0 && !userToCreate.tests.some(el => el.isChecked)) {
        return alert.setOpenAlert(true, 'Veuillez sélectionner au moins un test', 'error');
      }
      console.log('all checked');
      const response = await apiServiceNative.post(
        `/api/company-user-admin-create-carte`,
        userToCreate
      );

      console.log({response});

      if (response?.data === 'E-mail already exist') {
        return alert.setOpenAlert(
          true,
          'Cette adresse e-mail existe déjà, merci d’en utiliser une autre',
          'error'
        );
      }
      if (response?.code === 'transform-beneficiary') {
        setOpenPopin(true);
        return;
      }
      closeDrawer();
      updateArrayAfterUpdateCreate(response?.data);
    } catch (e) {
      console.log(e);
      closeDrawer();
    }
  };

  const handleConfirm = async () => {
    const response = await apiServiceNative.post(`/api/company-user-admin-create-carte`, {
      ...userToCreate,
      rattachBeneficiary: true,
    });
    if (response.success) {
      setOpenPopin(false);
      closeDrawer();
    }
  };

  return (
    <>
      <MarginContainer>
        <Typography style={{ fontSize: 13, color: '#000A8C' }}>
          {' '}
          <i>Avec cet outil vous pouvez inviter des utilisateurs, qui ne sont pas en prestation (bilan de compétences, coaching, etc.), à passer des tests sur la plateforme E-ORIENTACTION</i>{' '}
        </Typography>
      </MarginContainer>
      <div className={classes.marginTop}>
        <Typography className={classes.label}>Prénom* </Typography>

        <TextField
          id="outlined-basic"
          variant="outlined"
          className={classes.formInput}
          name="firstname"
          value={userToCreate.firstname}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeField('firstname', e.target.value)
          }
          InputProps={{
            readOnly: !!(mode === FORM_MODE.readonly),
          }}
          error={!!errorField.firstname}
        />
      </div>
      <div className={classes.marginTop}>
        <Typography className={classes.label}>Nom* </Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          className={classes.formInput}
          name="lastname"
          value={userToCreate.lastname}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeField('lastname', e.target.value)
          }
          InputProps={{
            readOnly: !!(mode === FORM_MODE.readonly),
          }}
          error={!!errorField.lastname}
        />
        <div className={classes.marginTop}>
          <Grid container={true} direction="row" spacing={1}>
            <Grid item={true} md={6} xs={12}>
              <div
                style={{
                  marginTop: '15px',
                }}>
                <Typography className={classes.label}>Date de naissance* </Typography>

                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className={classes.formInput}
                  name="dayOfBirth"
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeField('dayOfBirth', e.target.value)
                  }
                  value={
                    userToCreate.dayOfBirth
                      ? moment(userToCreate.dayOfBirth).format('YYYY-MM-DD')
                      : ''
                  }
                  type="date"
                  error={!!errorField.dayOfBirth}
                  // error={!!errors.dayOfBirth}
                  // helperText={errors.dayOfBirth ? 'Ce champ est obligatoire' : ''}
                />
              </div>
            </Grid>

            <Grid item={true} md={6} xs={12}>
              <div className={classes.marginTop}>
                <Typography className={classes.label}>Sexe* </Typography>

                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={userToCreate.gender || ''}
                    onChange={e => handleChangeField('gender', e.target.value)}
                    placeholder="Sexe"
                    name="gender"
                    className={classes.formControlSelect}
                    data-cy="form-gender">
                    <MenuItem value="Homme" data-cy="form-gender-homme">
                      Homme
                    </MenuItem>
                    <MenuItem value="Femme" data-cy="form-gender-femme">
                      Femme
                    </MenuItem>
                    <MenuItem value="Non binaire">Non binaire</MenuItem>
                  </Select>
                  {errorField.gender && (
                    <FormHelperText style={{ color: 'red', marginTop: '4px' }}>
                      Ce champ est obligatoire
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </Grid>
            <Grid item={true} md={12} sm={12} xs={12}>
              <Typography className={classes.label}>Adresse </Typography>
            </Grid>
            <Grid item={true} md={3} xs={12}>
              <div>
                <Typography className={classes.subLabel}>N° de voie </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className={classes.formInput}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeField('streetNumber', e.target.value)
                  }
                  // onChange={handleChange}
                  // name="streetNumber"
                  value={userToCreate.streetNumber || ''}
                  // error={!!errors.streetNumber}
                  InputProps={{
                    readOnly: !!(mode === FORM_MODE.readonly),
                  }}
                />
              </div>
            </Grid>
            <Grid item={true} md={9} xs={12}>
              <div>
                <Typography className={classes.subLabel}>Nom de la voie </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className={classes.formInput}
                  value={userToCreate.streetName}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeField('streetName', e.target.value)
                  }
                  // name="streetName"
                  // error={!!errors.streetName}
                  InputProps={{
                    readOnly: !!(mode === FORM_MODE.readonly),
                  }}
                />
              </div>
            </Grid>

            <Grid item={true} md={4} xs={12}>
              <div className={classes.marginTop}>
                <Typography className={classes.subLabel}>Code postal </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className={classes.formInput}
                  value={userToCreate.postal_code}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeField('postal_code', e.target.value)
                  }
                  // error={!!errors.postal_code}
                  InputProps={{
                    readOnly: !!(mode === FORM_MODE.readonly),
                  }}
                />
              </div>
            </Grid>
            <Grid item={true} md={8} xs={12}>
              <div className={classes.marginTop}>
                <Typography className={classes.subLabel}>Ville </Typography>

                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className={classes.formInput}
                  value={userToCreate.town}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeField('town', e.target.value)
                  }
                  // error={!!errors.town}
                  InputProps={{
                    readOnly: !!(mode === FORM_MODE.readonly),
                  }}
                />
              </div>
            </Grid>

            <Grid item={true} md={12} xs={12}>
              <Grid container={true} spacing={1}>
                <Grid item={true} md={8} xs={12}>
                  <div className={classes.marginTop}>
                    <Typography className={classes.label}>Téléphone* </Typography>
                    <PhoneInput
                      inputProps={{
                        name: 'phone',
                      }}
                      inputClass={classes.telInput}
                      specialLabel=""
                      country="fr"
                      value={userToCreate.phone}
                      onChange={value => handleChangeField('phone', value)}
                      masks={{
                        fr: '..........',
                      }}
                      preferredCountries={['fr', 'be', 'ch', 'ca']}
                      preserveOrder={['preferredCountries']}
                      inputStyle={{
                        width: '100%',
                        borderColor: errorField.phone ? 'red' : '#0E1247',
                      }}
                    />
                  </div>
                </Grid>
                <Grid item={true} md={4} xs={12}>
                  <div className={classes.marginTop}>
                    <Typography className={classes.label}>Langue </Typography>
                    <ReactFlagsSelect
                      countries={['FR', 'GB']}
                      showOptionLabel={false}
                      showSelectedLabel={false}
                      fullWidth={false}
                      selected="FR"
                      onSelect={e => console.log(e)}
                      selectButtonClassName={classes.selectLanguageButton}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item={true} md={12} xs={12}>
              <div className={classes.marginTop}>
                <Typography className={classes.label}>Diplôme* </Typography>
                <FormControl
                  variant="outlined"
                  style={{ width: '100%' }}
                  error={!!errorField.diploma}>
                  <Select
                    labelId="diploma-label-id"
                    id="diploma-id"
                    // value={diploma || ''}
                    // onChange={handleChange}
                    value={userToCreate.diploma || ''}
                    onChange={e => handleChangeField('diploma', e.target.value)}
                    placeholder="diploma"
                    name="diploma"
                    className={classes.formControlSelect}
                    data-cy="form-diploma">
                    <MenuItem value="Aucun diplôme">Aucun diplôme</MenuItem>
                    <MenuItem value="CAP/BEP">CAP/BEP</MenuItem>
                    <MenuItem value="BAC">BAC</MenuItem>
                    <MenuItem value="BAC+1">BAC+1</MenuItem>
                    <MenuItem value="BAC+2">BAC+2</MenuItem>
                    <MenuItem value="BAC+3">BAC+3</MenuItem>
                    <MenuItem value="BAC+4" data-cy="form-diploma-bac-4">
                      BAC+4
                    </MenuItem>
                    <MenuItem value="BAC+5">BAC+5</MenuItem>
                    <MenuItem value="BAC+6">BAC+6</MenuItem>
                    <MenuItem value="BAC+7">BAC+7</MenuItem>
                    <MenuItem value="BAC+8">BAC+8</MenuItem>
                  </Select>
                  {errorField.diploma && (
                    <FormHelperText style={{ color: 'red', marginTop: '4px' }}>
                      Ce champ est obligatoire
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </div>
        <SelectHieachy
          value={userConnected.id}
          alias={alias}
          errorForm={false}
          readOnly={true}
          title={`Administrateur de rattachement ${
            userConnected.role.type === 'admin' ? '' : '(facultatif)'
          }`}
          name="upper_hierarchy"
          handleChange={e => console.log('')}
          company_user_role={5}
          company={1}
          equal={true}
        />
        <div className={classes.marginTop}>
          <Typography className={classes.label}>Email* </Typography>

          <TextField
            id="outlined-basic"
            variant="outlined"
            className={classes.formInput}
            InputProps={{
              readOnly: !!(mode === FORM_MODE.readonly),
            }}
            name="email"
            value={userToCreate.email}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeField('email', e.target.value)
            }
            error={!!errorField.email}
            helperText={errorField.email ? 'Mauvais format' : ''}
          />
        </div>

        <MarginContainer>
          <Typography style={{ fontSize: 20, marginTop: 40 }} className={classes.bookChoiceLabel}> Les ressources </Typography>
        </MarginContainer>

        <MajorTestSelection
          tests={userToCreate.tests}
          handleChange={handleChange}
          loading={majorTestsLoading}
          service={{ id: 5 }}
        />

        {categorieTests && (
          <div style={{ marginTop: 30 }}>
            <MarginContainer>
              <Typography className={classes.bookChoiceLabel}> Les tests mineurs </Typography>
            </MarginContainer>
            {sortBy(categorieTests, (el: any) => el?.attributes?.priority)?.map(
              (categoryTest: any) => (
                <div>
                  <Typography color="primary" style={{ fontStyle: 'italic' }}>
                    {categoryTest?.attributes?.name}
                  </Typography>
                  {sortBy(
                    categoryTest.attributes.tests.data,
                    (test: any) => test?.attributes?.priority
                  )?.map(
                    (testCat: any) =>
                      !testCat?.attributes?.isArchived &&
                      !testCat?.attributes?.isDesabled && (
                        <div className={classes.bookChoiceContainer}>
                          <FormControlLabel
                            control={<Checkbox name="name" color="primary" value={testCat?.id} />}
                            label={testCat?.attributes?.title}
                            onChange={handleChangeTest}
                          />
                        </div>
                      )
                  )}
                </div>
              )
            )}
          </div>
        )}

        <br />
        <div
          style={{
            position: 'sticky',
            bottom: '0px',
            backgroundColor: '#fff',
            padding: '10px',
            marginTop: 100,
          }}>
          <Grid container={true} direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item={true}>
              <Button className={classes.btnWhite} onClick={closeDrawer}>
                <span className={classes.textHeader}>Annuler</span>
              </Button>
              <Button
                onClick={handleSubmit}
                className={classes.btnPrimary}
                data-cy="submit-create-edit-admin-benef">
                <span className={classes.textHeader}>Inviter l'utilisateur</span>
              </Button>
            </Grid>
          </Grid>
        </div>
        <OaPopin
          open={openPopin}
          title="Demande de rattachement d'un bénéficiaire."
          handleConfirm={handleConfirm}
          handleClose={() => setOpenPopin(false)}>
          <div className={classes.modalContent}>
            <Typography variant="h6" className={classes.modalText}>
              L'adresse e-mail que vous avez renseignée appartient à un utilisateur bénéficiaire
              non-rattaché. Souhaitez-vous le rattacher?
            </Typography>
          </div>
        </OaPopin>
      </div>
    </>
  );
};

export default BeneficiaryFormCarte;
