/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Layout, SimpleModalTest } from '@orientaction/components';
import { useStyles } from './style';
import TestMineur from './TestMineur';
import TestMajor from './TestMajor';
import Grid from '@material-ui/core/Grid';
import { cloneDeep, debounce, kebabCase } from 'lodash';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { useAlert, useCategoryTest, useLanguage, useMajorTest } from '@orientaction/hooks';
import { useEffect, useRef, useState, useContext } from 'react';
import { CompassUserProvider, CompassUserContext } from '@orientaction/Context';
import { Button, Drawer, Typography } from '@material-ui/core';
import { getMe } from '../../services/user.service';
import { getFromLS, isAdmin, updateUserInfoInLS } from '@orientaction/utils';
import { useHistory } from 'react-router-dom';
import { apiServiceNative } from '../../utils/fetchNative';
import { Close } from '@material-ui/icons';
import ChipTime from '@orientaction/commons/ChipTime';
import { userService } from '@orientaction/services';
import { useSelector } from 'react-redux';
import { apiState } from '@orientaction/api-actions';

const UserTest = () => {
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [minorTestLoading, setMinorTestLoading] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [testsToDo, setTestsToDo] = useState<any>([]);
  const [freeTests, setFreeTests] = useState<any>([]);
  const [listFilterMajor, setListFilterMajor] = useState<any>([]);
  const [listFilterMinor, setListFilterMinor] = useState<any>([]);
  const [listMajor, setListMajor] = useState<any>([]);
  const [listMinor, setListMinor] = useState<any>([]);
  const [majorTestLoading, setMajorTestLoading] = useState<boolean>(false);

  const { list, setList, getFilterTest } = useCategoryTest();

  const alert = useAlert();

  const classes = useStyles();

  const token = getFromLS('userToken') || '';
  // const userConnected = JSON.parse(getFromLS('userInfos') || '{}');
  const user = JSON.parse(getFromLS('userInfos') || '');

  const { getMyMajorTest, listMajorTest } = useMajorTest();
  const { language } = useLanguage();

  const { push } = useHistory();
  const { userInfos } = useContext<any>(CompassUserContext);

  const userInCardOrNonRattached = !userInfos?.rattachement || userInfos?.service?.id === 5;

  useEffect(() => {
    const fetchMe = async () => {
      const dataMe = await getMe(token);
      if (dataMe && dataMe.data) {
        setTestsToDo([...dataMe.data.tests_to_do]);
        if (!user?.secondConnexion && dataMe.data.isMyFirstTest) {
          // setOpenDrawer(dataMe.data.tests_to_do && dataMe?.data?.tests_to_do?.length > 0);
          // updateUserInfoInLS({ secondConnexion: true });
        }
        console.log({ user });
      }
    };
    const getFreeTest = async () => {
      const response = await apiServiceNative.get('/api/free-test?populate=tests');
      const testIds = response.data.attributes.tests.data.map((test: any) => test.id);
      setFreeTests(testIds);
    }
    if (token) {
      fetchMe();
      getFreeTest();
    }
    // setList([]);
    getMajorData('');
    fetchData('');
  }, [token]);

  const getMajorData = async (filter: any) => {
    setMajorTestLoading(true);

    try {
      const response = await getMyMajorTest(filter);
      setListFilterMajor(response);
      setListMajor(response);
      console.log({ response });
    } catch (err) {
      alert.openSimpleAlert();
    } finally {
      setMajorTestLoading(false);
    }
  };

  const fetchData = async (filter: any) => {
    setMinorTestLoading(true);

    try {
      const response = await getFilterTest(filter);
      setList(response);
      setListFilterMinor(response);
      setListMinor(response);
      console.log({ response });
    } catch (err) {
      alert.openSimpleAlert();
    } finally {
      setMinorTestLoading(false);
    }
  };

  const debouncedSearch = debounce(async criteria => {
    // fetchData(criteria);
    // getMyMajorTest(criteria);
    console.log({ criteria, listMajor, listMinor });
    setListFilterMajor(filterTestMajor(listMajor, criteria));
    setListFilterMinor(filterTestMinor(listMinor, criteria));
  }, 1000);

  const handleFilterChange = (e: any) => {
    e.preventDefault();
    setSearchFilter(e.target.value);
    setListFilterMajor(filterTestMajor(listMajor, e.target.value));
    setListFilterMinor(filterTestMinor(listMinor, e.target.value));
  };

  const search = () => {
    debouncedSearch(searchFilter);
  };

  const handleKeyUp = (e: any) => {
    debouncedSearch(e.target.value);
  };

  // useEffect(() => {
  //   return () => {
  //     debouncedSearch.cancel();
  //   };
  // }, [debouncedSearch]);

  // useEffect(() => {
  //   debouncedSearch(searchFilter);
  // }, [searchFilter]);

  const gotTo = async (idTest: any, title: string) => {
    push(`/test/${idTest}/${kebabCase(title)}`);
  };

  const [openModalTest, setOpenModalTest] = useState<boolean>(false);
  const { tabLayout } = useSelector(apiState);

  const checkFirstConnexion = async () => {
    const dataMe = await getMe(token as string);
    if (
      !dataMe.data.isMyFirstTest &&
      dataMe.data.beneficiaryType === 'rattached' &&
      !isAdmin(dataMe.data)
    ) {
      setOpenModalTest(true);
      await updateFirstConnexion();
    }
  };

  const updateFirstConnexion = async () => {
    try {
      updateUserInfoInLS({ isMyFirstTest: true });
      await userService.modifyUser({
        token,
        isMyFirstTest: true,
        id: user?.id,
      });
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (tabLayout === 1 && token) {
      checkFirstConnexion();
    }
  }, [tabLayout]);

  const filterTestMajor = (tests: any, keyword: string) => {
    setMajorTestLoading(true);
    console.log({keyword});
    if (keyword === '') {
      setMajorTestLoading(false);
      return tests;
    }
    const cloneData = [...cloneDeep(tests)];
    const filter = cloneData.filter((test: any) =>
      test?.name?.toLowerCase().includes(keyword.toLowerCase())
    );
    console.log({ filter });
    setMajorTestLoading(false);
    return filter;
  };

  const filterTestMinor = (tests: any, keyword: string) => {
    setMinorTestLoading(true);
    console.log({keyword});
    if (keyword === '') {
      setMinorTestLoading(false);
      return tests;
    }
    const cloneData = [...cloneDeep(tests)];
    const deepFilteredData = cloneData
      .map((item: any) => ({
        ...item,
        contentList: item?.contentList?.filter((subItem: any) =>
          subItem?.title.toLowerCase().includes(keyword.toLowerCase())
        ),
      }))
      ?.filter((item: any) => item?.contentList?.length > 0);
    console.log({deepFilteredData});
    setMinorTestLoading(false);
    return deepFilteredData;
  };

  return (
    <CompassUserProvider>
      <Layout isaddBackground={true} isNew={true}>
        <div className={classes.rootH}>
          <Grid
            container={true}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 40 }}>
            <Grid item={true} xs={12} md={3}>
              {testsToDo && testsToDo?.length > 0 && (
                <Button className={classes.btnTests} onClick={() => setOpenDrawer(true)}>
                  <div>
                    <div style={{ fontStyle: 'italic', fontWeight: 400, fontSize: 14 }}>
                      Vos tests conseillés
                    </div>
                    <div style={{ fontWeight: 600, fontSize: 16 }}>En attentes</div>
                  </div>
                </Button>
              )}
            </Grid>
            <Grid item={true} xs={12} md={3}>
              <Paper component="form" className={classes.searchPaper}>
                <InputBase
                  className={classes.searchInput}
                  placeholder="Rechercher un test"
                  value={searchFilter}
                  onChange={handleFilterChange}
                />
                <IconButton
                  onClick={search}
                  className={classes.searchIconButton}
                  aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
          </Grid>
        </div>
        <div className={classes.root}>
          <TestMajor
            language={language}
            searchKey={searchFilter}
            majorTests={listFilterMajor}
            loading={majorTestLoading}
            userInCardOrNonRattached={userInCardOrNonRattached}
          />
          <TestMineur minorTests={listFilterMinor} loading={minorTestLoading} freeTests={freeTests} />
        </div>
        <Drawer
          anchor="bottom"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          classes={{ root: classes.rootStyle, paper: classes.drawerContent }}>
          <IconButton
            aria-label="delete"
            className={classes.iconBtnImage}
            style={{ background: 'transparent' }}
            onClick={() => setOpenDrawer(false)}>
            <Close style={{ fontSize: 30, color: 'rgb(230, 60, 60)' }} />
          </IconButton>
          <div className={classes.contentDrawer}>
            <Typography variant="h2" color="primary" className={classes.titleTests}>
              Votre consultant(e) vous invite à passer les tests suivants
            </Typography>
            <div className={classes.separator} />
            <Typography variant="caption" className={classes.subTitleTests}>
              Cliquez sur un test pour y accéder
            </Typography>
            <div style={{ marginTop: 40 }}>
              {testsToDo &&
                testsToDo?.length > 0 &&
                testsToDo.map((test: any) => (
                  <div className={classes.testCard} onClick={() => gotTo(test?.id, test?.title)}>
                    <div>{test?.title}</div>
                    <ChipTime time={test.time} />
                  </div>
                ))}
            </div>
          </div>
        </Drawer>
        <SimpleModalTest
          open={openModalTest}
          handleClose={() => {
            setOpenModalTest(false);
            // setOpenDrawer(true);
            // updateUserInfoInLS({ secondConnexion: true });
          }}
          textClick="D'accord !"
          handleClick={() => {
            setOpenModalTest(false);
            // setOpenDrawer(true);
            // updateUserInfoInLS({ secondConnexion: true });
          }}
        />
      </Layout>
    </CompassUserProvider>
  );
};

export default UserTest;
