import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    background: '#FAFAFA',
    borderRadius: 5,
    width: '100%',
    height: '100%',
    minHeight: '692px',
    maxWidth: 1100,
  },
  blockContent: {
    padding: 20,
    position: 'relative',
    zIndex: 2,
  },
  title: {
    color: '#272727',
    fontFamily: 'Open Sans',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  titleType: {
    color: '#272727',
    fontFamily: 'Open Sans',
    fontSize: 12,
    fontStyle: 'normal',
    textTransform: 'none',
    marginRight: 15,
  },
  blockType: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  blockTypeInside: {
    display: 'flex',
    alignItems: 'center',
  },
  blockText: {
    width: '100%',
    minHeight: '160px',
    marginTop: 12,
  },
  blockText2: {
    width: '100%',
    minHeight: '338px',
    marginTop: 12,
  },
  select: {
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 500,
  },
  containerChoice: {
    width: '100%',
    height: 'calc(100% - 15px)',
    minHeight: '230px',
  },
  blockContentParent: {
    display: 'flex',
    flexDirection: 'column',
  },
  choice: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#f1f1f1',
    width: '100%',
    height: '100%',
  },
  iconBtnImage: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: '-3%',
    right: '-3%',
  },
  icon: {
    width: '14px',
    height: '14px',
  },

  titleEndGame: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#272727',
    fontWeight: 600,
    textAlign: 'center',
    marginBottom: '5px',
  },
  blockPadding: {
    padding: '25px 130px',
  },
}));
