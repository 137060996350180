// eslint-disable-next-line simple-import-sort/imports
import { FC } from 'react';
import { useStyles } from './style';
import { Button } from '@material-ui/core';
import { valueFormated } from '../formatValue';
import clsx from 'clsx';

interface ITableNormal {
  data: any[];
  header: any[];
  width: string;
  bgColor: string;
  btnClick: (field: string, data: any) => (e: any) => void;
  actionBtn: (data: any, type: string) => (e: any) => void;
}

const TableNormal: FC<ITableNormal> = ({ data, header, width, bgColor, btnClick, actionBtn }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {(header || []).map((item: any) => {
        return (
          <div key={item.key} className={classes.coulomContent} style={{ minWidth: item?.width }}>
            <div
              className={clsx({
                [classes.header]: true,
                [classes.headerTextCenter]: item?.option?.type === 'button',
              })}
              style={{ backgroundColor: bgColor }}>
              {item.label}
            </div>

            {(data || []).map((x: any, index: number) => {
              if (item?.option?.type === 'button') {
                return (
                  <div
                    className={classes.contentBtn}
                    key={`${item?.option?.type}-${item.key}-${index.toString()}`}>
                    <Button
                      onClick={btnClick(item.key, x)}
                      className={classes.button}
                      data-cy={`${item?.option?.type}-${item.key}-${index}`}>
                      {item?.option?.icon || item?.option?.labelText}
                    </Button>
                  </div>
                );
              }

              if (item?.option?.type === 'link') {
                return (
                  <div
                    className={classes.content}
                    key={`${item?.option?.type}-${item.key}-${index.toString()}`}>
                    <div onClick={btnClick(item.key, x)} className={classes.link}>
                      {valueFormated(x, item.key, item.option)}
                    </div>
                  </div>
                );
              }

              const value = valueFormated(x, item.key, item.option);

              return (
                <div
                  className={classes.content}
                  key={`${item?.option?.type}-${item.key}-${index.toString()}`}>
                  {value}
                  {/* {value?.length > 24 ? `${value?.slice(0, 25)}...` : value} */}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default TableNormal;
