// eslint-disable-next-line simple-import-sort/imports
import { FC } from 'react';
import { useStyles } from './style';
import Action from './the_action';

interface ITableNormal {
  data: any[];
  header: any[];
  bgColor: string;
  actionBtn: (data: any, type: string) => (e: any) => void;
}

const TableNormal: FC<ITableNormal> = ({ data, header, bgColor, actionBtn }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {(header || []).map((item: any) => {
        return (
          <div key={item.key} className={classes.coulomContent} style={{ width: item?.width }}>
            <div className={classes.header} style={{ backgroundColor: bgColor }}>
              {item.label}
            </div>

            {(data || []).map((x: any, index: number) => {
              return (
                <div className={classes.content}>
                  <Action
                    index={index}
                    rowOne={x}
                    listAction={item?.option?.listAction}
                    actionBtn={actionBtn}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default TableNormal;
